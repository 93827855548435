.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, cx-pickup-option-dialog .cx-pickup-option-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-pickup-option-dialog .cx-pickup-option-dialog, cx-pickup-option-dialog .modal.fade .cx-pickup-option-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-pickup-option-dialog .cx-pickup-option-dialog, cx-pickup-option-dialog .modal.fade .cx-pickup-option-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-pickup-option-dialog .cx-pickup-option-dialog, cx-pickup-option-dialog .modal.show .cx-pickup-option-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-pickup-option-dialog .cx-pickup-option-dialog, cx-pickup-option-dialog .modal.modal-static .cx-pickup-option-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content, cx-pickup-option-dialog .cx-pickup-option-dialog .modal-dialog-scrollable .cx-dialog-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-pickup-option-dialog .cx-pickup-option-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before, cx-pickup-option-dialog .cx-pickup-option-dialog::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable, cx-pickup-option-dialog .modal-dialog-scrollable.cx-pickup-option-dialog {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content, cx-pickup-option-dialog .cx-pickup-option-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-dialog-content, cx-pickup-option-dialog .modal-dialog-scrollable.cx-pickup-option-dialog .modal-content, cx-pickup-option-dialog .modal-dialog-scrollable.cx-pickup-option-dialog .cx-dialog-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before, cx-pickup-option-dialog .modal-dialog-scrollable.cx-pickup-option-dialog::before {
  content: none;
}

.modal-content, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, cx-pickup-option-dialog .cx-pickup-option-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content, cx-pickup-option-dialog .cx-pickup-option-dialog .modal-dialog-scrollable .cx-dialog-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered, cx-pickup-option-dialog .cx-pickup-option-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before, cx-pickup-option-dialog .cx-pickup-option-dialog::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: "bold";
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.cx-theme-high-contrast-dark .modal-header, .cx-theme-high-contrast-light .modal-header {
  background-color: var(--cx-color-background);
}
.modal-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content {
  border-radius: 0;
  border: none;
}
.cx-theme-high-contrast-dark .modal-content, .cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-theme-high-contrast-dark .cx-dialog-content, .cx-theme-high-contrast-light .modal-content, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-theme-high-contrast-light .cx-dialog-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .modal-content .close, .cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .close, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-theme-high-contrast-dark .cx-dialog-content .close, .cx-theme-high-contrast-light .modal-content .close, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .close, cx-pickup-option-dialog .cx-pickup-option-dialog .cx-theme-high-contrast-light .cx-dialog-content .close {
  color: var(--cx-color-text);
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.cxFeat_a11yExpandedFocusIndicator .modal .cx-dialog-header button.close,
.cxFeat_a11yExpandedFocusIndicator .modal button.close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}
.cx-theme-high-contrast-dark .cx-modal-content, .cx-theme-high-contrast-light .cx-modal-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .cx-modal-content .close, .cx-theme-high-contrast-light .cx-modal-content .close {
  color: var(--cx-color-text);
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

.cx-agnostic-table table {
  margin: 1rem 0;
  border-collapse: collapse;
  width: 100%;
}
.cx-agnostic-table th,
.cx-agnostic-table td {
  padding: 1.25rem 0.1rem 1.25rem 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.cx-agnostic-table th:nth-of-type(1),
.cx-agnostic-table td:nth-of-type(1) {
  text-align: start;
}
.cx-agnostic-table th:nth-last-of-type(1),
.cx-agnostic-table td:nth-last-of-type(1) {
  text-align: end;
}
.cx-agnostic-table tr {
  border-top: 1px solid var(--cx-color-medium);
  padding: 1.25rem 0;
}
.cx-agnostic-table th {
  vertical-align: bottom;
  text-transform: capitalize;
  text-align: center;
  color: var(--cx-color-secondary);
  font-weight: var(--cx-font-weight-bold);
  font-size: var(--cx-font-size, 0.875rem);
  white-space: nowrap;
}
.cxFeat_a11yImproveContrast .cx-agnostic-table th {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-agnostic-table th:nth-last-of-type(1) {
  padding-inline-end: 16px;
}
.cx-agnostic-table .cx-mobile-header {
  display: none;
}
.cx-agnostic-table .cx-mobile-only {
  display: none;
}
@media (max-width: 991.98px) {
  .cx-agnostic-table table,
  .cx-agnostic-table tr,
  .cx-agnostic-table td {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  .cx-agnostic-table td {
    align-items: center;
    padding: 0.313rem;
  }
  .cx-agnostic-table thead {
    display: none;
  }
  .cx-agnostic-table .cx-mobile-header {
    display: block;
    white-space: nowrap;
    text-align: start;
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    overflow-wrap: break-word;
    padding-inline-end: 1rem;
    min-width: 5rem;
  }
  .cx-agnostic-table .cx-mobile-only {
    display: flex;
  }
}
.cx-theme-high-contrast-dark .cx-agnostic-table tr, .cx-theme-high-contrast-light .cx-agnostic-table tr {
  border-color: var(--cx-color-dark);
}

cx-pickup-info .info-container {
  display: flex;
  flex-wrap: wrap;
}
cx-pickup-info .info-container .info-header {
  background-color: grey;
  width: 100%;
}
cx-pickup-info .info-container .info-location {
  width: 50%;
}
cx-pickup-info .info-container .store-hours {
  width: 50%;
}
cx-pickup-info .info-container .store-hours .store-hours-container {
  display: flex;
}
cx-pickup-info .info-container .store-hours .store-hours-container .store-hours-day {
  width: 50%;
}
cx-pickup-info .info-container .store-hours .store-hours-container .store-hours-time {
  width: 50%;
}

cx-pickup-option-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
cx-pickup-option-dialog .cx-pickup-option-dialog {
  align-items: normal;
}
@media (max-width: 991.98px) {
  cx-pickup-option-dialog .cx-pickup-option-dialog {
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content {
    height: 100%;
  }
}
cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .cx-dialog-header {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
  margin-top: 1.15rem;
}
cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .cx-dialog-header .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  margin-inline-start: var(--cx-margin-left, 0.875rem);
}
.cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .cx-dialog-header, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .cx-dialog-header {
  background-color: var(--cx-color-background);
}
cx-pickup-option-dialog .cx-pickup-option-dialog .cx-dialog-content .cx-dialog-body {
  overflow-y: scroll;
}
cx-pickup-option-dialog .cx-pickup-option-dialog .modal-body {
  padding-top: 0.75rem;
}
.cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-dialog-content, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-dialog-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-dialog-content .cx-dialog-body, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-dialog-content .cx-dialog-body {
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark cx-pickup-option-dialog .cx-dialog-content .close, .cx-theme-high-contrast-light cx-pickup-option-dialog .cx-dialog-content .close {
  color: var(--cx-color-text);
}

cx-pickup-options .cx-action-link {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
.cxFeat_a11yDeliveryMethodFieldset cx-pickup-options legend {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 15px 0 10px 0;
}

cx-store-schedule .cx-store-schedule-container {
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
}
@media (min-width: 768px) and (max-width: 767.98px) {
  cx-store-schedule .cx-store-schedule-container {
    width: 60%;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  cx-store-schedule .cx-store-schedule-container {
    width: 70%;
  }
}
@media (min-width: 992px) {
  cx-store-schedule .cx-store-schedule-container {
    width: 50%;
  }
}
cx-store-schedule .cx-store-schedule-container .cx-store-schedule-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  margin-bottom: 1rem;
}
cx-store-schedule .cx-store-schedule-container .cx-store-schedule-opening-times {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-light);
  line-height: var(--cx-line-height, 1.2222222222);
  display: flex;
  font-size: var(--cx-font-size, 0.875rem);
  line-height: var(--cx-line-height, 1.125rem);
}
cx-store-schedule .cx-store-schedule-container .cx-store-schedule-opening-times .cx-store-schedule-day-of-week {
  flex-grow: 1;
}

cx-store-search .cx-find-a-store-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  width: 100%;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-input {
  flex-grow: 1;
  padding-bottom: 1rem;
  flex-basis: 50%;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-input input {
  width: 100%;
  height: 48px;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-button {
  flex-basis: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 927px) {
  cx-store-search .cx-find-a-store-container .cx-find-a-store-button {
    flex-basis: 40%;
  }
}
@media (max-width: 767.98px) {
  cx-store-search .cx-find-a-store-container .cx-find-a-store-link-container {
    margin: auto;
  }
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-link-container .cx-find-a-store-link {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  color: #1672b7;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-link-container .cx-find-a-store-link:nth-of-type(1) {
  margin-inline-end: var(--cx-margin-right, 0.5rem);
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-link-container .cx-find-a-store-link:nth-of-type(2) {
  margin-inline-start: var(--cx-margin-right, 0.5rem);
}
@media (max-width: 991.98px) {
  cx-store-search .cx-find-a-store-container .cx-find-a-store-link-container .cx-find-a-store-link {
    display: inline-block;
    margin: auto;
    padding-top: 1.5rem;
  }
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-hide-out-of-stock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-hide-out-of-stock .cx-find-a-store-checkbox-group {
  display: flex;
}
@media (max-width: 767.98px) {
  cx-store-search .cx-find-a-store-container .cx-find-a-store-hide-out-of-stock .cx-find-a-store-checkbox-group {
    flex-basis: 100%;
  }
}
cx-store-search .cx-find-a-store-container .cx-find-a-store-hide-out-of-stock .cx-find-a-store-checkbox-group .cx-hide-out-of-stock-label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  user-select: none;
}

cx-store .cx-store {
  display: flex;
  border-top: solid thin #adb5bd;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
cx-store .cx-store-address {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  flex-grow: 1;
}
cx-store .cx-store-address .cx-store-name {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-store .cx-store-address .cx-store-full-address {
  padding-top: 1rem;
  line-height: var(--cx-line-height, 1.125rem);
}
cx-store .cx-store-distance {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  text-align: end;
}
cx-store .cx-store-opening-hours-toggle {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  color: #1672b7;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  padding-inline-start: 0;
  padding-top: 0.5rem;
  border: none;
  background: none;
}
cx-store .cx-store-opening-hours-toggle .cx-store-opening-hours-icon {
  color: var(--cx-color-secondary);
  opacity: var(--cx-opacity, 0.5);
}
cx-store .cx-stock-level {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-store .cx-store-stock-icon {
  color: var(--cx-color-secondary);
  opacity: var(--cx-opacity, 0.5);
}
cx-store .cx-store-out-of-stock {
  color: var(--cx-color-danger);
}
cx-store .cx-store-in-stock {
  color: var(--cx-color-success);
}
cx-store .cx-store-pick-up-from-here {
  padding: 0 0 25px 0;
  vertical-align: middle;
}

cx-set-preferred-store .icon-selected {
  color: var(--cx-color-primary);
}
cx-set-preferred-store .icon-not-selected {
  color: var(--cx-color-secondary);
}
cx-set-preferred-store .setpreferredstore-container {
  display: flex;
  margin-top: 0.75rem;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight, 600);
}
cx-set-preferred-store .setpreferredstore-container .set-preferred-heading {
  padding-inline-start: 0.5rem;
  text-decoration: underline;
  border: none;
  background: none;
}

cx-my-preferred-store .btn, cx-my-preferred-store .container cx-card .cx-card-actions {
  display: inline-block;
  font-weight: "normal";
  color: #14293a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 14px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  cx-my-preferred-store .btn, cx-my-preferred-store .container cx-card .cx-card-actions {
    transition: none;
  }
}
cx-my-preferred-store .btn:hover, cx-my-preferred-store .container cx-card .cx-card-actions:hover {
  color: #14293a;
  text-decoration: none;
}
cx-my-preferred-store .btn:focus, cx-my-preferred-store .container cx-card .cx-card-actions:focus, cx-my-preferred-store .btn.focus, cx-my-preferred-store .container cx-card .focus.cx-card-actions {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
cx-my-preferred-store .btn.disabled, cx-my-preferred-store .container cx-card .disabled.cx-card-actions, cx-my-preferred-store .btn:disabled, cx-my-preferred-store .container cx-card .cx-card-actions:disabled {
  opacity: 0.65;
}
cx-my-preferred-store .btn:not(:disabled):not(.disabled), cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled) {
  cursor: pointer;
}
cx-my-preferred-store a.btn.disabled, cx-my-preferred-store .container cx-card a.disabled.cx-card-actions,
cx-my-preferred-store fieldset:disabled a.btn,
cx-my-preferred-store fieldset:disabled .container cx-card a.cx-card-actions,
cx-my-preferred-store .container cx-card fieldset:disabled a.cx-card-actions {
  pointer-events: none;
}
cx-my-preferred-store .btn-primary {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-my-preferred-store .btn-primary:hover {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
}
cx-my-preferred-store .btn-primary:focus, cx-my-preferred-store .btn-primary.focus {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
cx-my-preferred-store .btn-primary.disabled, cx-my-preferred-store .btn-primary:disabled {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-my-preferred-store .btn-primary:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-primary:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #185f94;
  border-color: #165889;
}
cx-my-preferred-store .btn-primary:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-primary:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
cx-my-preferred-store .btn-secondary {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-secondary:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
cx-my-preferred-store .btn-secondary:focus, cx-my-preferred-store .btn-secondary.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-my-preferred-store .btn-secondary.disabled, cx-my-preferred-store .btn-secondary:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-secondary:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-secondary:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
cx-my-preferred-store .btn-secondary:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-my-preferred-store .btn-success {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-my-preferred-store .btn-success:hover {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
}
cx-my-preferred-store .btn-success:focus, cx-my-preferred-store .btn-success.focus {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
cx-my-preferred-store .btn-success.disabled, cx-my-preferred-store .btn-success:disabled {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-my-preferred-store .btn-success:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-success:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #275e15;
  border-color: #225313;
}
cx-my-preferred-store .btn-success:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-success:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
cx-my-preferred-store .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-my-preferred-store .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
cx-my-preferred-store .btn-info:focus, cx-my-preferred-store .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-my-preferred-store .btn-info.disabled, cx-my-preferred-store .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-my-preferred-store .btn-info:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-info:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
cx-my-preferred-store .btn-info:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-info:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-my-preferred-store .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-my-preferred-store .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
cx-my-preferred-store .btn-warning:focus, cx-my-preferred-store .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-my-preferred-store .btn-warning.disabled, cx-my-preferred-store .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-my-preferred-store .btn-warning:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-warning:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
cx-my-preferred-store .btn-warning:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-warning:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-my-preferred-store .btn-danger {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-my-preferred-store .btn-danger:hover {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
}
cx-my-preferred-store .btn-danger:focus, cx-my-preferred-store .btn-danger.focus {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
cx-my-preferred-store .btn-danger.disabled, cx-my-preferred-store .btn-danger:disabled {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-my-preferred-store .btn-danger:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-danger:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a80002;
  border-color: #9b0001;
}
cx-my-preferred-store .btn-danger:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-danger:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
cx-my-preferred-store .btn-light {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-my-preferred-store .btn-light:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
}
cx-my-preferred-store .btn-light:focus, cx-my-preferred-store .btn-light.focus {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
cx-my-preferred-store .btn-light.disabled, cx-my-preferred-store .btn-light:disabled {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-my-preferred-store .btn-light:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-light:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d8d8d8;
  border-color: #d1d1d1;
}
cx-my-preferred-store .btn-light:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-light:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
cx-my-preferred-store .btn-dark {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-dark:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
cx-my-preferred-store .btn-dark:focus, cx-my-preferred-store .btn-dark.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-my-preferred-store .btn-dark.disabled, cx-my-preferred-store .btn-dark:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-dark:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-dark:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
cx-my-preferred-store .btn-dark:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-dark:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-my-preferred-store .btn-background {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-my-preferred-store .btn-background:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
cx-my-preferred-store .btn-background:focus, cx-my-preferred-store .btn-background.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
cx-my-preferred-store .btn-background.disabled, cx-my-preferred-store .btn-background:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-my-preferred-store .btn-background:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-background:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-background.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
cx-my-preferred-store .btn-background:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-background:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
cx-my-preferred-store .btn-text {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-my-preferred-store .btn-text:hover {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
}
cx-my-preferred-store .btn-text:focus, cx-my-preferred-store .btn-text.focus {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
cx-my-preferred-store .btn-text.disabled, cx-my-preferred-store .btn-text:disabled {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-my-preferred-store .btn-text:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-text:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-text.dropdown-toggle {
  color: #fff;
  background-color: #070e14;
  border-color: #04070b;
}
cx-my-preferred-store .btn-text:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-text:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
cx-my-preferred-store .btn-inverse {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-my-preferred-store .btn-inverse:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
cx-my-preferred-store .btn-inverse:focus, cx-my-preferred-store .btn-inverse.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
cx-my-preferred-store .btn-inverse.disabled, cx-my-preferred-store .btn-inverse:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-my-preferred-store .btn-inverse:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-inverse:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-inverse.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
cx-my-preferred-store .btn-inverse:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-inverse:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
cx-my-preferred-store .btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-my-preferred-store .btn-transparent:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
cx-my-preferred-store .btn-transparent:focus, cx-my-preferred-store .btn-transparent.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-my-preferred-store .btn-transparent.disabled, cx-my-preferred-store .btn-transparent:disabled {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-my-preferred-store .btn-transparent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-transparent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-transparent.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
cx-my-preferred-store .btn-transparent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-transparent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-my-preferred-store .btn-primary-accent {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-my-preferred-store .btn-primary-accent:hover {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
}
cx-my-preferred-store .btn-primary-accent:focus, cx-my-preferred-store .btn-primary-accent.focus {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
cx-my-preferred-store .btn-primary-accent.disabled, cx-my-preferred-store .btn-primary-accent:disabled {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-my-preferred-store .btn-primary-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-primary-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #03416e;
  border-color: #033a61;
}
cx-my-preferred-store .btn-primary-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-primary-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
cx-my-preferred-store .btn-success-accent {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-my-preferred-store .btn-success-accent:hover {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
}
cx-my-preferred-store .btn-success-accent:focus, cx-my-preferred-store .btn-success-accent.focus {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
cx-my-preferred-store .btn-success-accent.disabled, cx-my-preferred-store .btn-success-accent:disabled {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-my-preferred-store .btn-success-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-success-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #d7f4b8;
  border-color: #d1f3ac;
}
cx-my-preferred-store .btn-success-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-success-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
cx-my-preferred-store .btn-danger-accent {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-my-preferred-store .btn-danger-accent:hover {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
}
cx-my-preferred-store .btn-danger-accent:focus, cx-my-preferred-store .btn-danger-accent.focus {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
cx-my-preferred-store .btn-danger-accent.disabled, cx-my-preferred-store .btn-danger-accent:disabled {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-my-preferred-store .btn-danger-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-danger-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffbebe;
  border-color: #ffb1b1;
}
cx-my-preferred-store .btn-danger-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-danger-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
cx-my-preferred-store .btn-warning-accent {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-my-preferred-store .btn-warning-accent:hover {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
}
cx-my-preferred-store .btn-warning-accent:focus, cx-my-preferred-store .btn-warning-accent.focus {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
cx-my-preferred-store .btn-warning-accent.disabled, cx-my-preferred-store .btn-warning-accent:disabled {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-my-preferred-store .btn-warning-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-warning-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffe5ac;
  border-color: #ffe19f;
}
cx-my-preferred-store .btn-warning-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-warning-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
cx-my-preferred-store .btn-info-accent {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-my-preferred-store .btn-info-accent:hover {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
}
cx-my-preferred-store .btn-info-accent:focus, cx-my-preferred-store .btn-info-accent.focus {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
cx-my-preferred-store .btn-info-accent.disabled, cx-my-preferred-store .btn-info-accent:disabled {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-my-preferred-store .btn-info-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-info-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #add7fd;
  border-color: #a0d1fc;
}
cx-my-preferred-store .btn-info-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-info-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
cx-my-preferred-store .btn-medium {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-my-preferred-store .btn-medium:hover {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
}
cx-my-preferred-store .btn-medium:focus, cx-my-preferred-store .btn-medium.focus {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
cx-my-preferred-store .btn-medium.disabled, cx-my-preferred-store .btn-medium:disabled {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-my-preferred-store .btn-medium:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-medium:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-medium.dropdown-toggle {
  color: #212529;
  background-color: #b7bcc4;
  border-color: #b0b5be;
}
cx-my-preferred-store .btn-medium:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-medium:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
cx-my-preferred-store .btn-background-dark {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-my-preferred-store .btn-background-dark:hover {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
}
cx-my-preferred-store .btn-background-dark:focus, cx-my-preferred-store .btn-background-dark.focus {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
cx-my-preferred-store .btn-background-dark.disabled, cx-my-preferred-store .btn-background-dark:disabled {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-my-preferred-store .btn-background-dark:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-background-dark:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #0e1118;
  border-color: #090b10;
}
cx-my-preferred-store .btn-background-dark:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-background-dark:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
cx-my-preferred-store .btn-visual-focus {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-my-preferred-store .btn-visual-focus:hover {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
}
cx-my-preferred-store .btn-visual-focus:focus, cx-my-preferred-store .btn-visual-focus.focus {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
cx-my-preferred-store .btn-visual-focus.disabled, cx-my-preferred-store .btn-visual-focus:disabled {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-my-preferred-store .btn-visual-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-visual-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-visual-focus.dropdown-toggle {
  color: #fff;
  background-color: #3d7df4;
  border-color: #3174f4;
}
cx-my-preferred-store .btn-visual-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-visual-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
cx-my-preferred-store .btn-background-focus {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-my-preferred-store .btn-background-focus:hover {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
}
cx-my-preferred-store .btn-background-focus:focus, cx-my-preferred-store .btn-background-focus.focus {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
cx-my-preferred-store .btn-background-focus.disabled, cx-my-preferred-store .btn-background-focus:disabled {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-my-preferred-store .btn-background-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-background-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-background-focus.dropdown-toggle {
  color: #fff;
  background-color: rgba(32, 154, 241, 0.1);
  border-color: rgba(20, 149, 240, 0.1);
}
cx-my-preferred-store .btn-background-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-background-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
cx-my-preferred-store .btn-border-focus {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-my-preferred-store .btn-border-focus:hover {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
}
cx-my-preferred-store .btn-border-focus:focus, cx-my-preferred-store .btn-border-focus.focus {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
cx-my-preferred-store .btn-border-focus.disabled, cx-my-preferred-store .btn-border-focus:disabled {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-my-preferred-store .btn-border-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-border-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(125, 181, 222, 0.6);
  border-color: rgba(115, 175, 219, 0.6);
}
cx-my-preferred-store .btn-border-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-border-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
cx-my-preferred-store .btn-outline-primary {
  color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-my-preferred-store .btn-outline-primary:hover {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-my-preferred-store .btn-outline-primary:focus, cx-my-preferred-store .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
cx-my-preferred-store .btn-outline-primary.disabled, cx-my-preferred-store .btn-outline-primary:disabled {
  color: #1f7bc0;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-primary:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-primary:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-my-preferred-store .btn-outline-primary:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
cx-my-preferred-store .btn-outline-secondary {
  color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-secondary:focus, cx-my-preferred-store .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-my-preferred-store .btn-outline-secondary.disabled, cx-my-preferred-store .btn-outline-secondary:disabled {
  color: #6c7079;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-secondary:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-my-preferred-store .btn-outline-success {
  color: #38871f;
  border-color: #38871f;
}
cx-my-preferred-store .btn-outline-success:hover {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-my-preferred-store .btn-outline-success:focus, cx-my-preferred-store .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
cx-my-preferred-store .btn-outline-success.disabled, cx-my-preferred-store .btn-outline-success:disabled {
  color: #38871f;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-success:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-success:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-my-preferred-store .btn-outline-success:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
cx-my-preferred-store .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
cx-my-preferred-store .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-my-preferred-store .btn-outline-info:focus, cx-my-preferred-store .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-my-preferred-store .btn-outline-info.disabled, cx-my-preferred-store .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-info:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-info:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-my-preferred-store .btn-outline-info:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-my-preferred-store .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
cx-my-preferred-store .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-my-preferred-store .btn-outline-warning:focus, cx-my-preferred-store .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-my-preferred-store .btn-outline-warning.disabled, cx-my-preferred-store .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-warning:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-warning:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-my-preferred-store .btn-outline-warning:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-my-preferred-store .btn-outline-danger {
  color: #db0002;
  border-color: #db0002;
}
cx-my-preferred-store .btn-outline-danger:hover {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-my-preferred-store .btn-outline-danger:focus, cx-my-preferred-store .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
cx-my-preferred-store .btn-outline-danger.disabled, cx-my-preferred-store .btn-outline-danger:disabled {
  color: #db0002;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-danger:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-danger:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-my-preferred-store .btn-outline-danger:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
cx-my-preferred-store .btn-outline-light {
  color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-my-preferred-store .btn-outline-light:hover {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-my-preferred-store .btn-outline-light:focus, cx-my-preferred-store .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
cx-my-preferred-store .btn-outline-light.disabled, cx-my-preferred-store .btn-outline-light:disabled {
  color: #f1f1f1;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-light:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-light:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-my-preferred-store .btn-outline-light:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
cx-my-preferred-store .btn-outline-dark {
  color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-dark:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-dark:focus, cx-my-preferred-store .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-my-preferred-store .btn-outline-dark.disabled, cx-my-preferred-store .btn-outline-dark:disabled {
  color: #6c7079;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-dark:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-dark:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-my-preferred-store .btn-outline-dark:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-my-preferred-store .btn-outline-background {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-my-preferred-store .btn-outline-background:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-my-preferred-store .btn-outline-background:focus, cx-my-preferred-store .btn-outline-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
cx-my-preferred-store .btn-outline-background.disabled, cx-my-preferred-store .btn-outline-background:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-background:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-background:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-background.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-my-preferred-store .btn-outline-background:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-background:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
cx-my-preferred-store .btn-outline-text, cx-my-preferred-store .container cx-card .cx-card-actions {
  color: #14293a;
  border-color: #14293a;
}
cx-my-preferred-store .btn-outline-text:hover, cx-my-preferred-store .container cx-card .cx-card-actions:hover {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-my-preferred-store .btn-outline-text:focus, cx-my-preferred-store .container cx-card .cx-card-actions:focus, cx-my-preferred-store .btn-outline-text.focus, cx-my-preferred-store .container cx-card .focus.cx-card-actions {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
cx-my-preferred-store .btn-outline-text.disabled, cx-my-preferred-store .container cx-card .disabled.cx-card-actions, cx-my-preferred-store .btn-outline-text:disabled, cx-my-preferred-store .container cx-card .cx-card-actions:disabled {
  color: #14293a;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-text:not(:disabled):not(.disabled):active, cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-text:not(:disabled):not(.disabled).active, cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-text.dropdown-toggle, .show > cx-my-preferred-store .container cx-card .dropdown-toggle.cx-card-actions {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-my-preferred-store .btn-outline-text:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-text:not(:disabled):not(.disabled).active:focus, cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-text.dropdown-toggle:focus, .show > cx-my-preferred-store .container cx-card .dropdown-toggle.cx-card-actions:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
cx-my-preferred-store .btn-outline-inverse {
  color: #ffffff;
  border-color: #ffffff;
}
cx-my-preferred-store .btn-outline-inverse:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-my-preferred-store .btn-outline-inverse:focus, cx-my-preferred-store .btn-outline-inverse.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-my-preferred-store .btn-outline-inverse.disabled, cx-my-preferred-store .btn-outline-inverse:disabled {
  color: #ffffff;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-inverse:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-inverse:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-inverse.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-my-preferred-store .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-inverse:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-my-preferred-store .btn-outline-transparent {
  color: transparent;
  border-color: transparent;
}
cx-my-preferred-store .btn-outline-transparent:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-my-preferred-store .btn-outline-transparent:focus, cx-my-preferred-store .btn-outline-transparent.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
cx-my-preferred-store .btn-outline-transparent.disabled, cx-my-preferred-store .btn-outline-transparent:disabled {
  color: transparent;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-transparent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-transparent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-transparent.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-my-preferred-store .btn-outline-transparent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-transparent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
cx-my-preferred-store .btn-outline-primary-accent {
  color: #055f9f;
  border-color: #055f9f;
}
cx-my-preferred-store .btn-outline-primary-accent:hover {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-my-preferred-store .btn-outline-primary-accent:focus, cx-my-preferred-store .btn-outline-primary-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
cx-my-preferred-store .btn-outline-primary-accent.disabled, cx-my-preferred-store .btn-outline-primary-accent:disabled {
  color: #055f9f;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-primary-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-primary-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-my-preferred-store .btn-outline-primary-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-primary-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
cx-my-preferred-store .btn-outline-success-accent {
  color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-my-preferred-store .btn-outline-success-accent:hover {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-my-preferred-store .btn-outline-success-accent:focus, cx-my-preferred-store .btn-outline-success-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
cx-my-preferred-store .btn-outline-success-accent.disabled, cx-my-preferred-store .btn-outline-success-accent:disabled {
  color: #f0fbe4;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-success-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-success-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-my-preferred-store .btn-outline-success-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-success-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
cx-my-preferred-store .btn-outline-danger-accent {
  color: #fff1f1;
  border-color: #fff1f1;
}
cx-my-preferred-store .btn-outline-danger-accent:hover {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-my-preferred-store .btn-outline-danger-accent:focus, cx-my-preferred-store .btn-outline-danger-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
cx-my-preferred-store .btn-outline-danger-accent.disabled, cx-my-preferred-store .btn-outline-danger-accent:disabled {
  color: #fff1f1;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-danger-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-danger-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-my-preferred-store .btn-outline-danger-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-danger-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
cx-my-preferred-store .btn-outline-warning-accent {
  color: #fff5df;
  border-color: #fff5df;
}
cx-my-preferred-store .btn-outline-warning-accent:hover {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-my-preferred-store .btn-outline-warning-accent:focus, cx-my-preferred-store .btn-outline-warning-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
cx-my-preferred-store .btn-outline-warning-accent.disabled, cx-my-preferred-store .btn-outline-warning-accent:disabled {
  color: #fff5df;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-warning-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-warning-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-my-preferred-store .btn-outline-warning-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-warning-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
cx-my-preferred-store .btn-outline-info-accent {
  color: #deeffe;
  border-color: #deeffe;
}
cx-my-preferred-store .btn-outline-info-accent:hover {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-my-preferred-store .btn-outline-info-accent:focus, cx-my-preferred-store .btn-outline-info-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
cx-my-preferred-store .btn-outline-info-accent.disabled, cx-my-preferred-store .btn-outline-info-accent:disabled {
  color: #deeffe;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-info-accent:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-info-accent:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-my-preferred-store .btn-outline-info-accent:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-info-accent:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
cx-my-preferred-store .btn-outline-medium {
  color: #d3d6db;
  border-color: #d3d6db;
}
cx-my-preferred-store .btn-outline-medium:hover {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-my-preferred-store .btn-outline-medium:focus, cx-my-preferred-store .btn-outline-medium.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
cx-my-preferred-store .btn-outline-medium.disabled, cx-my-preferred-store .btn-outline-medium:disabled {
  color: #d3d6db;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-medium:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-medium:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-medium.dropdown-toggle {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-my-preferred-store .btn-outline-medium:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-medium:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
cx-my-preferred-store .btn-outline-background-dark {
  color: #212738;
  border-color: #212738;
}
cx-my-preferred-store .btn-outline-background-dark:hover {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-my-preferred-store .btn-outline-background-dark:focus, cx-my-preferred-store .btn-outline-background-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
cx-my-preferred-store .btn-outline-background-dark.disabled, cx-my-preferred-store .btn-outline-background-dark:disabled {
  color: #212738;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-background-dark:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-background-dark:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-my-preferred-store .btn-outline-background-dark:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-background-dark:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
cx-my-preferred-store .btn-outline-visual-focus {
  color: #6d9df7;
  border-color: #6d9df7;
}
cx-my-preferred-store .btn-outline-visual-focus:hover {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-my-preferred-store .btn-outline-visual-focus:focus, cx-my-preferred-store .btn-outline-visual-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
cx-my-preferred-store .btn-outline-visual-focus.disabled, cx-my-preferred-store .btn-outline-visual-focus:disabled {
  color: #6d9df7;
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-visual-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-visual-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-visual-focus.dropdown-toggle {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-my-preferred-store .btn-outline-visual-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-visual-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
cx-my-preferred-store .btn-outline-background-focus {
  color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-my-preferred-store .btn-outline-background-focus:hover {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-my-preferred-store .btn-outline-background-focus:focus, cx-my-preferred-store .btn-outline-background-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
cx-my-preferred-store .btn-outline-background-focus.disabled, cx-my-preferred-store .btn-outline-background-focus:disabled {
  color: rgba(80, 176, 244, 0.1);
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-background-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-background-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-background-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-my-preferred-store .btn-outline-background-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-background-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
cx-my-preferred-store .btn-outline-border-focus {
  color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-my-preferred-store .btn-outline-border-focus:hover {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-my-preferred-store .btn-outline-border-focus:focus, cx-my-preferred-store .btn-outline-border-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
cx-my-preferred-store .btn-outline-border-focus.disabled, cx-my-preferred-store .btn-outline-border-focus:disabled {
  color: rgba(166, 204, 232, 0.6);
  background-color: transparent;
}
cx-my-preferred-store .btn-outline-border-focus:not(:disabled):not(.disabled):active, cx-my-preferred-store .btn-outline-border-focus:not(:disabled):not(.disabled).active, .show > cx-my-preferred-store .btn-outline-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-my-preferred-store .btn-outline-border-focus:not(:disabled):not(.disabled):active:focus, cx-my-preferred-store .btn-outline-border-focus:not(:disabled):not(.disabled).active:focus, .show > cx-my-preferred-store .btn-outline-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
cx-my-preferred-store .btn-link {
  font-weight: "normal";
  color: #1f7bc0;
  text-decoration: none;
}
cx-my-preferred-store .btn-link:hover {
  color: #14517e;
  text-decoration: underline;
}
cx-my-preferred-store .btn-link:focus, cx-my-preferred-store .btn-link.focus {
  text-decoration: underline;
}
cx-my-preferred-store .btn-link:disabled, cx-my-preferred-store .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
cx-my-preferred-store .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
cx-my-preferred-store .btn-sm, cx-my-preferred-store .container cx-card .cx-card-actions {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
cx-my-preferred-store .btn-block, cx-my-preferred-store .container cx-card .cx-card-actions {
  display: block;
  width: 100%;
}
cx-my-preferred-store .btn-block + .btn-block, cx-my-preferred-store .container cx-card .cx-card-actions + .btn-block, cx-my-preferred-store .container cx-card .btn-block + .cx-card-actions, cx-my-preferred-store .container cx-card .cx-card-actions + .cx-card-actions {
  margin-top: 0.5rem;
}
cx-my-preferred-store input[type=submit].btn-block, cx-my-preferred-store .container cx-card input[type=submit].cx-card-actions,
cx-my-preferred-store input[type=reset].btn-block,
cx-my-preferred-store .container cx-card input[type=reset].cx-card-actions,
cx-my-preferred-store input[type=button].btn-block,
cx-my-preferred-store .container cx-card input[type=button].cx-card-actions {
  width: 100%;
}
cx-my-preferred-store .cx-store-opening-hours-toggle {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  color: #1672b7;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  padding-inline-start: 0;
  padding-top: 0.5rem;
  border: none;
  background: none;
}
cx-my-preferred-store .cx-store-opening-hours-toggle .cx-store-opening-hours-icon {
  color: var(--cx-color-secondary);
  opacity: var(--cx-opacity, 0.5);
}
cx-my-preferred-store .cx-my-preferred-store-heading {
  margin-bottom: 1.5rem;
}
cx-my-preferred-store .container .cx-my-preferred-store-heading {
  margin-top: 1rem;
  margin-bottom: 0;
  color: var(--cx-color-primary);
}
.cxFeat_a11yImproveContrast cx-my-preferred-store .container .cx-my-preferred-store-heading {
  font-size: var(--cx-font-size, 1.75rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-my-preferred-store .container [data-test-id=preferredStoreAddressBook] .cx-store-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}
cx-my-preferred-store .container cx-card .cx-card-actions {
  border-width: 2px;
  border-style: solid;
  text-decoration: none;
  margin-top: 1rem;
}
cx-my-preferred-store .container cx-card .cx-card-actions:hover {
  color: var(--cx-color-primary);
  border-width: 2px;
  border-style: solid;
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-inverse);
}
cx-my-preferred-store .container cx-card .cx-card-actions.active {
  color: var(--cx-color-primary);
  border-width: 2px;
  border-style: solid;
  background-color: var(--cx-color-inverse);
}
cx-my-preferred-store .container cx-card .cx-card-actions.disabled {
  color: var(--cx-color-light);
  border-width: 2px;
  border-style: solid;
  border-color: var(--cx-color-light);
  opacity: 1;
}
cx-my-preferred-store .container cx-card .cx-card-actions .link.cx-action-link {
  margin: 0.5rem;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
}
.cx-theme-high-contrast-dark cx-my-preferred-store .btn-outline-text, .cx-theme-high-contrast-dark cx-my-preferred-store .container cx-card .cx-card-actions, .cx-theme-high-contrast-light cx-my-preferred-store .btn-outline-text, .cx-theme-high-contrast-light cx-my-preferred-store .container cx-card .cx-card-actions {
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-light);
}
.cx-theme-high-contrast-dark cx-my-preferred-store .container cx-card .cx-card-actions, .cx-theme-high-contrast-light cx-my-preferred-store .container cx-card .cx-card-actions {
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-light);
}
.cx-theme-high-contrast-dark cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-light cx-my-preferred-store .container cx-card .cx-card-actions:not(:disabled):not(.disabled):active {
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-light);
}

cx-pick-up-in-store-items-details .cx-code,
cx-pick-up-in-store-items-details .cx-name .cx-property {
  margin: 0;
  font-weight: var(--cx-font-weight-normal);
}
cx-pick-up-in-store-items-details .cx-name-value {
  margin-bottom: 0.25rem;
  font-weight: var(--cx-font-weight-semi);
  text-decoration: var(--cx-text-decoration, underline);
  text-decoration-color: #d3d6db;
  text-transform: capitalize;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details-store-heading {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-pick-up-in-store-items-details .cx-delivery-pointof-service-item {
  border-bottom: 1px solid #d3d6db;
  padding: 1rem 1rem 1rem 2rem;
  min-height: 10rem;
}
cx-pick-up-in-store-items-details .cx-delivery-pointof-service-item-header {
  font-size: var(--cx-font-size, 0.9rem);
  font-weight: var(--cx-font-weight-semi);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-top: 1px solid #d3d6db;
  border-bottom: 1px solid #d3d6db;
}
cx-pick-up-in-store-items-details .cx-delivery-pointof-service-item-header .cx-price-header {
  text-align: var(--cx-text-align, center);
  padding: 0;
}
cx-pick-up-in-store-items-details .cx-delivery-pointof-service-item-header .cx-quantity-header {
  text-align: var(--cx-text-align, start);
  padding: 0;
}
cx-pick-up-in-store-items-details .cx-delivery-pointof-service-item-header .cx-total-header {
  text-align: var(--cx-text-align, center);
  padding: 0;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details-heading {
  margin: 1rem 0;
  padding: 1rem 1rem 1rem 2rem;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: 600;
  background-color: var(--cx-color-background);
}
cx-pick-up-in-store-items-details .cx-total .cx-value {
  font-weight: var(--cx-font-weight-normal);
}
cx-pick-up-in-store-items-details .cx-checkout-in-store-pick-up-location {
  padding-top: 1.5rem;
  border: 1px solid #d3d6db;
  font-weight: 400;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details {
  padding: 0 1rem 1rem 2rem;
  min-height: 5vh;
  border: 1px solid #d3d6db;
  border-radius: 0.75rem;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details > div {
  display: flex;
  width: 100%;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details > div > .cx-pickup-items-details-store-address {
  flex: 0 0 50%;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details > div > .cx-pickup-items-details-store-schedule {
  flex: 0 0 40%;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details > div > .cx-pickup-items-details-store-schedule .cx-store-schedule-container {
  width: 100%;
  margin-top: 1rem;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details > div > .cx-pickup-items-details-edit-icon {
  flex: 0 0 10%;
  text-align: center;
  margin-top: 1rem;
  color: var(--cx-color-primary);
}
cx-pick-up-in-store-items-details .cx-checkout-btns {
  margin-top: 5rem;
  margin-bottom: 1.125rem;
}
cx-pick-up-in-store-items-details .cx-pickup-items-details:not(:first-of-type) {
  background: var(--cx-color-background);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

cx-pickup-in-store-order-consignment {
  width: 100%;
  margin-top: -1rem;
}
cx-pickup-in-store-order-consignment .cx-consignment-details {
  background-color: var(--cx-color-background);
}
cx-pickup-in-store-order-consignment .cx-deliveryPointOfService-address {
  padding: 0 30px 15px 30px;
}
cx-pickup-in-store-order-consignment .cx-deliveryPointOfService-heading {
  font-weight: var(--cx-font-weight-semi);
  padding-bottom: 0.5rem;
}