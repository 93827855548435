.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

cx-epd-visualization-paged-list {
  display: flex;
  flex: 100%;
  --cx-speed: 0.5;
  flex-direction: column;
}
cx-epd-visualization-paged-list > h3 {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  cx-epd-visualization-paged-list > h3 {
    margin-bottom: 3rem;
  }
}
cx-epd-visualization-paged-list .list-panel {
  display: flex;
  justify-content: space-between;
}
cx-epd-visualization-paged-list .list-panel .slides {
  flex: auto;
  position: relative;
}
cx-epd-visualization-paged-list .list-panel .slides .slide {
  transition: 0.6s all;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
}
cx-epd-visualization-paged-list .list-panel .slides .slide:not(.active) {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: none;
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item {
  opacity: 0;
  z-index: -1;
  transition: 0.4s all;
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item.active {
  opacity: 1;
  z-index: 1;
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item:nth-child(1) {
  transition-delay: calc(var(--cx-speed, 1) * 0.25s);
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item:nth-child(2) {
  transition-delay: calc(var(--cx-speed, 1) * 0.5s);
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item:nth-child(3) {
  transition-delay: calc(var(--cx-speed, 1) * 0.75s);
}
cx-epd-visualization-paged-list .list-panel .slides .slide .item:nth-child(4) {
  transition-delay: calc(var(--cx-speed, 1) * 1s);
}
cx-epd-visualization-paged-list button {
  color: var(--cx-color-light);
}
cx-epd-visualization-paged-list button:not(:disabled) {
  cursor: pointer;
}
cx-epd-visualization-paged-list .indicators {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
cx-epd-visualization-paged-list .indicators button {
  border: none;
  padding: 10px;
  margin: 0;
  transition: 0.6s all;
  background-color: transparent;
}
cx-epd-visualization-paged-list .indicators button[disabled] {
  color: var(--cx-color-primary);
}
cx-epd-visualization-paged-list .indicators button:not(:disabled):hover {
  color: var(--cx-color-secondary);
}
cx-epd-visualization-paged-list .previous,
cx-epd-visualization-paged-list .next {
  background-color: transparent;
  border: none;
  font-size: 2rem;
}
cx-epd-visualization-paged-list .previous:disabled,
cx-epd-visualization-paged-list .next:disabled {
  opacity: 0.5;
}
cx-epd-visualization-paged-list .previous:not(:disabled):hover,
cx-epd-visualization-paged-list .next:not(:disabled):hover {
  color: var(--cx-color-primary);
}

cx-epd-visualization-product-filter {
  display: block;
}
cx-epd-visualization-product-filter .form-control[type=text]::placeholder {
  color: var(--cx-color-light);
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}
cx-epd-visualization-product-filter .form-control[type=text] {
  background-color: var(--cx-color-inverse);
}
@media (max-width: 1199.98px) {
  cx-epd-visualization-product-filter .form-control[type=text] {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.6);
    height: 36px;
  }
}
cx-epd-visualization-product-filter .form-control[type=text]:focus {
  background-color: var(--cx-color-inverse);
}
cx-epd-visualization-product-filter .search-wrapper {
  position: relative;
}
cx-epd-visualization-product-filter .search-wrapper input {
  padding: 0 46px 0 1em;
}
cx-epd-visualization-product-filter .search-wrapper cx-icon {
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  cx-epd-visualization-product-filter .search-wrapper cx-icon {
    top: 10px;
  }
}
cx-epd-visualization-product-filter .search-wrapper .disabled-action {
  pointer-events: none;
  opacity: 0.5;
}

cx-epd-visualization-product-list {
  --cx-thumbnail-width: 48px;
  --cx-thumbnail-height: 48px;
  --cx-highlight-border-width: 3px;
  --cx-highlight-border-color: var(--cx-color-primary);
  display: flex;
}
cx-epd-visualization-product-list a {
  display: block;
  color: var(--cx-color-text);
  outline: none;
}
cx-epd-visualization-product-list a:hover, cx-epd-visualization-product-list a:focus {
  color: var(--cx-color-primary);
  text-decoration: underline;
}
cx-epd-visualization-product-list a:focus {
  position: relative;
  z-index: 1;
}
cx-epd-visualization-product-list cx-media {
  align-self: center;
  width: 48px;
  height: 48px;
}
cx-epd-visualization-product-list .list-header {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  justify-content: space-between;
  text-transform: uppercase;
  padding: 0.5rem 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent var(--cx-color-light) transparent;
}
cx-epd-visualization-product-list .list-item {
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent var(--cx-color-light) transparent;
}
cx-epd-visualization-product-list .list-item.selected {
  background-color: #efefef;
  border-color: transparent transparent var(--cx-color-light) transparent;
}
cx-epd-visualization-product-list .list-item:focus {
  outline-offset: -6px;
}
cx-epd-visualization-product-list .thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
cx-epd-visualization-product-list .thumbnail {
  width: calc(var(--cx-thumbnail-width) + 2 * var(--cx-highlight-border-width));
  height: calc(var(--cx-thumbnail-height) + 2 * var(--cx-highlight-border-width));
  border-style: solid;
  border-color: transparent;
  border-width: var(--cx-highlight-border-width);
  border-radius: 0.25em;
  margin: 0 0.5em 0 0;
}
cx-epd-visualization-product-list .thumbnail.selected {
  border-color: var(--cx-highlight-border-color);
}
cx-epd-visualization-product-list .thumbnail cx-media.is-missing {
  width: var(--cx-thumbnail-width);
  height: var(--cx-thumbnail-height);
}
cx-epd-visualization-product-list .thumbnail cx-media.is-missing img {
  display: none;
}
cx-epd-visualization-product-list .cx-item-list-header {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 0;
  padding: 1.125rem 0;
  text-transform: uppercase;
  color: var(--cx-color-secondary);
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
}
@media (max-width: 767.98px) {
  cx-epd-visualization-product-list .cx-item-list-header {
    padding: 1rem 0;
  }
}
cx-epd-visualization-product-list .cx-item-list-desc {
  text-align: start;
  padding: 0;
}
cx-epd-visualization-product-list .cx-item-list-price {
  text-align: end;
  padding: 0 1em 0 0;
}
cx-epd-visualization-product-list .cx-name {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  overflow-wrap: break-word;
  padding: 0;
}
@media (max-width: 1199.98px) {
  cx-epd-visualization-product-list .cx-name {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-epd-visualization-product-list .cx-name .cx-link {
  color: var(--cx-color-text);
  text-decoration: none;
}
cx-epd-visualization-product-list .cx-name .cx-link:hover {
  color: var(--cx-color-primary);
}
cx-epd-visualization-product-list .cx-code {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-secondary);
  overflow-wrap: break-word;
  padding: 0.625rem 0 0 0;
}
cx-epd-visualization-product-list .cx-price {
  display: flex;
  justify-content: flex-end;
  text-align: end;
  overflow-wrap: break-word;
  padding: 0 1em 0 0;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}
@media (max-width: 1199.98px) {
  cx-epd-visualization-product-list .cx-price {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-epd-visualization-product-list .cx-out-of-stock {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}
@media (max-width: 1199.98px) {
  cx-epd-visualization-product-list .cx-out-of-stock {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-epd-visualization-product-list .cx-add-to-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
cx-epd-visualization-product-list .flex-column {
  display: flex;
  flex-direction: column;
}
cx-epd-visualization-product-list .flex-row {
  display: flex;
  flex-direction: row;
}
cx-epd-visualization-product-list cx-epd-visualization-compact-add-to-cart .btn {
  min-width: 30px;
  padding: 0;
}

html[dir=rtl] cx-epd-visualization-product-list .cx-item-list-price {
  padding: 0 0 0 1em;
}
html[dir=rtl] cx-epd-visualization-product-list .cx-price {
  padding: 0 0 0 1em;
}
html[dir=rtl] cx-epd-visualization-product-list .thumbnail {
  margin: 0 0 0 0.5em;
}

cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references),
cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) {
  padding: 1em 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references),
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) {
    flex-direction: row;
  }
}
cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) cx-epd-visualization-viewer,
cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) cx-epd-visualization-viewer {
  width: 100%;
  height: 20em;
}
@media (min-width: 768px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) cx-epd-visualization-viewer,
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) cx-epd-visualization-viewer {
    width: calc(50% - 0.5em);
    height: 50em;
  }
}
@media (min-width: 992px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) cx-epd-visualization-viewer,
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) cx-epd-visualization-viewer {
    width: calc(60% - 0.5em);
    height: 50em;
  }
}
cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden),
cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden) {
  width: 100%;
}
@media (min-width: 768px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden),
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden) {
    width: calc(50% - 0.5em);
  }
}
@media (min-width: 992px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden),
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) .visual-picking-product-list-container:not(.viewportHidden) {
    width: calc(40% - 0.5em);
  }
}
cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) .visual-picking-product-list-container.viewportHidden,
cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) .visual-picking-product-list-container.viewportHidden {
  width: 100%;
}
cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) cx-epd-visualization-product-filter,
cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) cx-epd-visualization-product-filter {
  margin: 0.25rem 0 1rem 0;
}
@media (min-width: 768px) {
  cx-epd-visualization-visual-picking-tab .container-fluid:not(.no-product-references) cx-epd-visualization-product-filter,
  cx-epd-visualization-visual-picking-tab .container:not(.no-product-references) cx-epd-visualization-product-filter {
    margin: 0;
  }
}

.ProductDetailsPageTemplate .cx-tab-paragraph-content:has(cx-epd-visualization-visual-picking-tab) {
  padding: 1em 0;
}

cx-epd-visualization-animation-slider .disabled {
  opacity: 0.65;
}
cx-epd-visualization-animation-slider .disabled .cx-epd-visualization-animation-slider-bar,
cx-epd-visualization-animation-slider .disabled .cx-epd-visualization-animation-slider-pointer {
  cursor: default;
  color: #6c757d;
  border-color: #6c757d;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider {
  width: 8em;
  margin: auto 0 auto 0;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider-wrapper {
  margin: 0;
  display: inline-block;
  position: relative;
  height: 7px;
  width: 100%;
  vertical-align: middle;
  user-select: none;
  touch-action: pan-y;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider-wrapper .cx-epd-visualization-animation-slider-span {
  white-space: nowrap;
  position: absolute;
  display: inline-block;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider-wrapper .cx-epd-visualization-animation-slider-bar-wrapper {
  left: 0;
  box-sizing: border-box;
  margin-top: -6.5px;
  padding-top: 6.5px;
  width: 100%;
  height: 20px;
  z-index: 1;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider-wrapper .cx-epd-visualization-animation-slider-bar {
  left: 0;
  width: 100%;
  z-index: 1;
  background: var(--cx-color-inverse);
  height: 7px;
  border-color: var(--cx-color-text);
  border-width: 2px;
  border-style: solid;
  border-radius: 3.5px;
}
cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider-wrapper .cx-epd-visualization-animation-slider-pointer {
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: -6.5px;
  background: var(--cx-color-inverse);
  border-color: var(--cx-color-text);
  border-width: 2px;
  border-style: solid;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0px rgba(150, 150, 150, 0.5);
}

cx-epd-visualization-viewer-toolbar-button {
  display: inline-block;
  pointer-events: none;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer-toolbar-button {
    width: auto;
  }
}
cx-epd-visualization-viewer-toolbar-button .buttonVBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0.25em;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer-toolbar-button .buttonVBox {
    justify-content: center;
  }
}
cx-epd-visualization-viewer-toolbar-button cx-icon {
  padding: 0 auto;
}
cx-epd-visualization-viewer-toolbar-button .buttonText {
  padding: 0.125em 0 0 0;
}
cx-epd-visualization-viewer-toolbar-button .btn {
  max-height: none;
  height: 55px;
  width: 65px;
  padding: inherit;
  border-radius: 0.25em;
  pointer-events: all;
  font-size: var(--cx-font-size, 0.9rem) !important;
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer-toolbar-button .btn {
    height: 44px;
    width: 36px;
  }
}
cx-epd-visualization-viewer-toolbar-button .btn:focus, cx-epd-visualization-viewer-toolbar-button .btn.focus {
  box-shadow: none !important;
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
  outline-offset: 0;
}
.cxFeat_a11yImproveContrast cx-epd-visualization-viewer-toolbar-button .btn:focus, .cxFeat_a11yImproveContrast cx-epd-visualization-viewer-toolbar-button .btn.focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows cx-epd-visualization-viewer-toolbar-button .btn:focus, .cxFeat_a11yVisibleFocusOverflows cx-epd-visualization-viewer-toolbar-button .btn.focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn:focus, .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn.focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn:focus .ng-select-container, .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn.focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn:focus[type=radio], .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn.focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn:focus > cx-media, .cxFeat_a11yMobileVisibleFocus cx-epd-visualization-viewer-toolbar-button .btn.focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

cx-epd-visualization-viewer-toolbar-button .btn.disabled {
  pointer-events: none;
}
cx-epd-visualization-viewer-toolbar-button .btn-link {
  color: var(--cx-color-text) !important;
  text-decoration: none !important;
  font-size: 0.875rem;
}
cx-epd-visualization-viewer-toolbar-button .btn-link .buttonText {
  margin: auto 0 0 0;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer-toolbar-button .btn-link .buttonText {
    display: none;
  }
}
cx-epd-visualization-viewer-toolbar-button button:disabled {
  color: var(--cx-color-dark) !important;
}
cx-epd-visualization-viewer-toolbar-button .checked {
  background-color: var(--cx-color-background);
}

cx-epd-visualization-viewer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border-color: var(--cx-color-light);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25em;
  padding: 1px;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer {
    border-radius: 0;
    padding: 0;
    border-width: 0;
  }
}
cx-epd-visualization-viewer:focus-within {
  border-color: var(--cx-color-secondary);
}
cx-epd-visualization-viewer .sapVizKitViewport {
  grid-row: 1;
  grid-column: 1;
}
cx-epd-visualization-viewer .sapVizKitViewport:focus {
  outline-style: unset;
  z-index: 0 !important;
}
cx-epd-visualization-viewer .sapVizKitViewport:focus canvas {
  outline-style: unset;
}
cx-epd-visualization-viewer .content-type-symbol {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  margin: 0.5em 1em 0 auto;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer .content-type-symbol {
    display: none;
  }
}
cx-epd-visualization-viewer .content-type-symbol .content-type-text {
  font-size: var(--cx-font-size, 1.25rem);
  opacity: 30%;
  text-shadow: 1px 1px 1px rgba(150, 150, 150, 0.5);
}
cx-epd-visualization-viewer .overlay {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  pointer-events: none;
}
cx-epd-visualization-viewer .bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
cx-epd-visualization-viewer .toolbar {
  display: flex;
  flex-direction: row;
  pointer-events: initial;
  justify-content: center;
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer .toolbar {
    justify-content: flex-start;
  }
}
cx-epd-visualization-viewer .toolbarHBox {
  display: flex;
  flex-direction: row;
  padding: 1em;
  margin-bottom: 1em;
  opacity: 95%;
  border-radius: 0.25em;
  background-color: var(--cx-color-inverse);
  border-color: #f1f1f1;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 991.98px) {
  cx-epd-visualization-viewer .toolbarHBox {
    padding: 0.25em;
    margin: 0.25em;
  }
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer .toolbarHBox {
    padding: 0;
    margin: 0;
  }
}
cx-epd-visualization-viewer .toolbarItem {
  margin: 0 0 0 0.5em;
}
cx-epd-visualization-viewer cx-epd-visualization-animation-slider {
  display: none;
}
@media (min-width: 768px) {
  cx-epd-visualization-viewer cx-epd-visualization-animation-slider {
    display: block;
  }
}
cx-epd-visualization-viewer cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider {
  width: 5em;
  margin: 0 0.5em;
}
@media (min-width: 1200px) {
  cx-epd-visualization-viewer cx-epd-visualization-animation-slider .cx-epd-visualization-animation-slider {
    width: 8em;
  }
}
@media (max-width: 767.98px) {
  cx-epd-visualization-viewer .isolateButton {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-epd-visualization-viewer .turntableButton {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-epd-visualization-viewer .panButton {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-epd-visualization-viewer .zoomButton {
    display: none;
  }
}
cx-epd-visualization-viewer .cx-spinner {
  margin: auto;
}