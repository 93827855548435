.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list {
    max-width: 100%;
    padding: 0;
  }
}
cx-invoices-list .cx-invoices-list-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-top: 1px solid var(--cx-color-light);
  border-bottom: 1px solid var(--cx-color-light);
}
cx-invoices-list .cx-invoices-list-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-invoices-list .cx-invoices-list-table th:last-child {
  text-align: end;
}
cx-invoices-list .cx-invoices-list-table td {
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-invoices-list .cx-invoices-list-table td {
    text-align: start;
  }
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-invoices-list .cx-invoices-list-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-invoices-list .cx-invoices-list-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
cx-invoices-list .cx-invoices-list-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-header {
    padding-top: 40px;
    padding-inline-end: 20px;
    padding-bottom: 0;
    padding-inline-start: 20px;
  }
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-thead-mobile {
    display: none;
  }
}
cx-invoices-list .cx-invoices-list-code .cx-invoices-list-value {
  text-decoration: none;
}
cx-invoices-list .cx-invoices-list-externalSystem .cx-invoices-list-value {
  text-decoration: none;
}
cx-invoices-list .cx-invoices-list-attachment .cx-invoices-list-value {
  text-decoration: underline;
  text-align: end;
  color: var(--cx-color-primary);
}
cx-invoices-list .cx-invoices-list-date {
  text-align: center;
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-date {
    text-align: start;
  }
}
cx-invoices-list .cx-invoices-list-status {
  text-align: center;
  text-transform: capitalize;
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-status {
    text-align: start;
  }
}
cx-invoices-list .cx-invoices-list-status .status-open {
  color: var(--cx-color-success);
}
cx-invoices-list .cx-invoices-list-status .status-closed {
  color: var(--cx-color-secondary);
}
cx-invoices-list .cx-invoices-list-monetary {
  text-align: end;
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-monetary {
    text-align: start;
  }
}
cx-invoices-list .cx-invoices-list-label {
  color: var(--cx-color-secondary);
}
@media (min-width: 992px) {
  cx-invoices-list .cx-invoices-list-label {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 200px;
    min-height: 48px;
  }
}
cx-invoices-list .cx-invoices-list-value {
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    font-weight: "normal";
  }
}
cx-invoices-list .cx-invoices-list-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
cx-invoices-list .cx-invoices-list-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-invoices-list .cx-invoices-list-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-form-group {
    padding: 1.25rem;
    width: 100%;
  }
}
cx-invoices-list .cx-invoices-list-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-invoices-list .cx-invoices-list-sort.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-invoices-list .cx-invoices-list-sort.bottom {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-pagination {
    margin: 0 auto;
  }
}
cx-invoices-list .cx-invoices-list-no-document {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
  min-height: 415px;
  padding-top: 2rem;
  border-top: 1px solid var(--cx-color-light);
  justify-content: center;
}
@media (max-width: 767.98px) {
  cx-invoices-list .cx-invoices-list-no-document {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  cx-invoices-list .cx-invoices-list-row:nth-child(odd) {
    background-color: var(--cx-color-background);
  }
}
cx-invoices-list .cx-invoices-list-attachment-btn {
  color: var(--cx-color-primary);
  background-color: inherit;
  border: 0;
}
cx-invoices-list .cx-invoices-list-attachment-icon {
  font-size: 1.4rem;
}
cx-invoices-list .cx-invoices-list-attachment-text {
  font-weight: var(--cx-font-weight-bold);
  padding-inline-start: 0.5rem;
}
@media (min-width: 992px) {
  cx-invoices-list .cx-invoices-list-attachment-text {
    display: none;
  }
}