.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

cx-checkout-progress .cx-nav,
cx-checkout-progress-mobile-top .cx-nav,
cx-checkout-progress-mobile-bottom .cx-nav {
  font-size: 1.188rem;
  font-weight: var(--cx-font-weight-bold);
  margin: 0;
  line-height: 26px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  cx-checkout-progress .cx-nav,
  cx-checkout-progress-mobile-top .cx-nav,
  cx-checkout-progress-mobile-bottom .cx-nav {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-semi);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-checkout-progress .cx-nav .cx-list,
cx-checkout-progress-mobile-top .cx-nav .cx-list,
cx-checkout-progress-mobile-bottom .cx-nav .cx-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
}
cx-checkout-progress .cx-nav .cx-item,
cx-checkout-progress-mobile-top .cx-nav .cx-item,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item {
  display: flex;
  justify-content: center;
  flex: 1;
  color: var(--cx-color-text);
  padding: 0 0 1.063rem 0;
  border-bottom: 2px solid var(--cx-color-text);
}
cx-checkout-progress .cx-nav .cx-item ::after,
cx-checkout-progress-mobile-top .cx-nav .cx-item ::after,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item ::after {
  padding-inline-end: 0.75em;
  content: "";
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  cx-checkout-progress .cx-nav .cx-item ::after,
  cx-checkout-progress-mobile-top .cx-nav .cx-item ::after,
  cx-checkout-progress-mobile-bottom .cx-nav .cx-item ::after {
    padding-inline-end: 0.5rem;
  }
}
cx-checkout-progress .cx-nav .cx-item.active,
cx-checkout-progress-mobile-top .cx-nav .cx-item.active,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item.active {
  border-color: var(--cx-color-primary);
}
cx-checkout-progress .cx-nav .cx-item.disabled,
cx-checkout-progress-mobile-top .cx-nav .cx-item.disabled,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item.disabled {
  border-color: var(--cx-color-medium);
}
cx-checkout-progress .cx-nav .cx-item:first-child ::before,
cx-checkout-progress-mobile-top .cx-nav .cx-item:first-child ::before,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item:first-child ::before {
  padding: 0;
  content: "";
}
cx-checkout-progress .cx-nav .cx-item:last-child ::after,
cx-checkout-progress-mobile-top .cx-nav .cx-item:last-child ::after,
cx-checkout-progress-mobile-bottom .cx-nav .cx-item:last-child ::after {
  padding: 0;
  content: "";
}
cx-checkout-progress .cx-link,
cx-checkout-progress-mobile-top .cx-link,
cx-checkout-progress-mobile-bottom .cx-link {
  color: var(--cx-color-text);
  cursor: pointer;
  text-decoration: none;
  text-align: start;
}
cx-checkout-progress .cx-link.disabled,
cx-checkout-progress-mobile-top .cx-link.disabled,
cx-checkout-progress-mobile-bottom .cx-link.disabled {
  color: var(--cx-color-secondary);
  cursor: not-allowed;
  pointer-events: none;
}
cx-checkout-progress .cx-link.disabled::after,
cx-checkout-progress-mobile-top .cx-link.disabled::after,
cx-checkout-progress-mobile-bottom .cx-link.disabled::after {
  color: var(--cx-color-secondary);
}
cx-checkout-progress .cx-link.active,
cx-checkout-progress-mobile-top .cx-link.active,
cx-checkout-progress-mobile-bottom .cx-link.active {
  color: var(--cx-color-primary);
}
cx-checkout-progress .cx-link.active::after,
cx-checkout-progress-mobile-top .cx-link.active::after,
cx-checkout-progress-mobile-bottom .cx-link.active::after {
  color: var(--cx-color-primary);
}
cx-checkout-progress .cx-media .cx-list-media,
cx-checkout-progress-mobile-top .cx-media .cx-list-media,
cx-checkout-progress-mobile-bottom .cx-media .cx-list-media {
  display: none;
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  justify-content: space-between;
  align-items: center;
  line-height: 4.75rem;
  min-width: 100%;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--cx-color-light);
  margin: 0;
}
cx-checkout-progress .cx-media .cx-list-media.active,
cx-checkout-progress-mobile-top .cx-media .cx-list-media.active,
cx-checkout-progress-mobile-bottom .cx-media .cx-list-media.active {
  background-color: var(--cx-color-background);
  border: 0;
}
cx-checkout-progress .cx-media .cx-list-media button,
cx-checkout-progress-mobile-top .cx-media .cx-list-media button,
cx-checkout-progress-mobile-bottom .cx-media .cx-list-media button {
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 991.98px) {
  cx-checkout-progress .cx-media .cx-list-media,
  cx-checkout-progress-mobile-top .cx-media .cx-list-media,
  cx-checkout-progress-mobile-bottom .cx-media .cx-list-media {
    display: flex;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);
    padding: 0 1.875rem;
  }
}
@media (max-width: 767.98px) {
  cx-checkout-progress .cx-media .cx-list-media,
  cx-checkout-progress-mobile-top .cx-media .cx-list-media,
  cx-checkout-progress-mobile-bottom .cx-media .cx-list-media {
    padding: 0 1.375rem;
  }
}
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress .cx-media .cx-list-media,
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress-mobile-top .cx-media .cx-list-media,
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress-mobile-bottom .cx-media .cx-list-media {
  line-height: unset;
}
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress .cx-media .cx-title,
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress-mobile-top .cx-media .cx-title,
.cxFeat_a11yTruncatedTextForResponsiveView cx-checkout-progress-mobile-bottom .cx-media .cx-title {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (max-width: 991.98px) {
  cx-billing-address-form {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-billing-address-form .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-billing-address-form .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-billing-address-form .container {
    width: 100%;
  }
}
cx-billing-address-form .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-billing-address-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-billing-address-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-billing-address-form .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-billing-address-form .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-billing-address-form .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-billing-address-form .cx-checkout-body.row {
    padding: 0;
  }
}
cx-billing-address-form .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-billing-address-form .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-billing-address-form .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}

cx-payment-method {
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  margin-top: 32px;
  margin-bottom: 30px;
  padding-inline-start: 1.875rem;
  padding-inline-end: 1.875rem;
  padding-top: 1.875rem;
}
@media (max-width: 991.98px) {
  cx-payment-method {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-payment-method .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-payment-method .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-payment-method .container {
    width: 100%;
  }
}
cx-payment-method .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-payment-method .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-payment-method .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-payment-method .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-body.row {
    padding: 0;
  }
}
cx-payment-method .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-payment-method .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-payment-method {
    background-color: var(--cx-color-transparent);
  }
}
cx-payment-method .cx-payment.container {
  padding: 0;
}
cx-payment-method .cx-payment-card {
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 992px) {
  cx-payment-method .cx-payment-card:nth-child(odd) {
    padding-inline-end: 15px;
  }
  cx-payment-method .cx-payment-card:nth-child(even) {
    padding-inline-start: 15px;
  }
}
cx-payment-method .cx-payment-card-inner {
  height: 100%;
  background-color: var(--cx-color-inverse);
  cursor: pointer;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-payment-card-inner {
    background-color: var(--cx-color-inverse);
  }
}
cx-payment-method .cx-card-img-container .fab::before,
cx-payment-method .cx-card-img-container .fas::before {
  font-size: var(--cx-font-size, 48px);
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-payment-method .cx-checkout-btns {
  padding-bottom: 1rem;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-btns .btn-secondary {
    margin-bottom: 1rem;
  }
}
cx-payment-method .cx-checkout-btns-top {
  display: flex;
  justify-content: flex-end;
  padding-inline-start: 1.875rem;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-btns-top {
    display: none;
  }
}
cx-payment-method .cx-checkout-btns-top .btn {
  width: 50%;
}
cx-payment-method .cx-checkout-btns-bottom {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  cx-payment-method .cx-checkout-btns-bottom {
    display: none;
  }
}
cx-payment-method .cx-checkout-btns-bottom .btn-secondary {
  margin-bottom: 0;
}
cx-payment-method .cx-checkout-body {
  display: flex;
  align-items: stretch;
}
@media (max-width: 991.98px) {
  cx-payment-method .cx-checkout-body.row {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  cx-payment-form {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-payment-form .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-payment-form .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-payment-form .container {
    width: 100%;
  }
}
cx-payment-form .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-payment-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-payment-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-payment-form .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-payment-form .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-payment-form .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-payment-form .cx-checkout-body.row {
    padding: 0;
  }
}
cx-payment-form .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-payment-form .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-payment-form .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
cx-payment-form .cx-payment-form-tooltip {
  margin: 0 0 0 0.1875rem;
  font-size: 18px;
  position: relative;
  top: 2px;
  color: var(--cx-color-secondary);
}
cx-payment-form .cx-payment-form-billing {
  margin: 0 0 1.25rem 0;
}
cx-payment-form .cx-payment-form-billing-address {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding: 0.875rem 0 1.25rem 0;
}
cx-payment-form .cx-payment-form-exp-date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
cx-payment-form .cx-payment-form-exp-date legend {
  font-size: 1rem;
}
cx-payment-form .cx-payment-form-exp-date-wrapper {
  flex: 1;
}
cx-payment-form .cx-payment-form-exp-date-wrapper:first-of-type {
  padding-inline-end: 1rem;
}
cx-payment-form .form-check {
  margin: 0;
}
cx-payment-form .label-content {
  white-space: nowrap;
}

cx-checkout-billing-address-form .cx-payment-form-billing-address {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding: 0.875rem 0 1.25rem 0;
}

@media (max-width: 991.98px) {
  cx-review-submit {
    padding: 20px 0;
  }
}
cx-review-submit .cx-review {
  padding: 0 0 1rem;
}
cx-review-submit .cx-review-title {
  text-transform: uppercase;
  margin: 0 auto;
  padding: 2.375rem 0 1.25rem 0;
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-normal);
}
cx-review-submit .cx-review-summary {
  margin: 0;
  background-color: var(--cx-color-background);
  padding: 15px 0;
}
cx-review-submit .cx-review-summary-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  word-break: break-all;
}
cx-review-submit .cx-review-summary-edit-step {
  padding: 0 20px 20px;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-review-summary-edit-step {
    padding: 10px 0 0;
  }
}
@media (min-width: 992px) {
  cx-review-submit .cx-review-summary .cx-review-payment-col {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
cx-review-submit .cx-review-summary .cx-review-summary-card {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-review-summary .cx-review-summary-card {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: var(--cx-color-light);
    min-height: auto;
  }
}
@media (min-width: 992px) {
  cx-review-submit .cx-review-summary .cx-review-summary-card:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
}
cx-review-submit .cx-review-summary .cx-review-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
cx-review-submit .cx-review-summary .cx-review-summary-card .cx-card-paragraph-title {
  font-style: italic;
}
cx-review-submit .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step {
  align-self: flex-start;
  padding: 20px;
  font-size: 1.125rem;
}
cx-review-submit .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step a {
  color: var(--cx-color-dark);
}
cx-review-submit .form-check {
  padding: 0;
  margin: 0;
}
cx-review-submit .col-md-4 {
  padding: 0;
}
cx-review-submit .cx-review-cart-total {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 2.625rem 0 0.5rem 0;
}
cx-review-submit .cx-review-cart-heading {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding: 1.375rem 0;
  margin: 0;
  border-style: solid;
  border-width: 1px 0;
  border-color: var(--cx-color-light);
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-review-cart-heading {
    border-width: 1px 0 0;
    max-width: 100%;
    min-width: 100%;
    padding: 1.375rem 0 1.375rem 3.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-review-submit .cx-review-cart-heading {
    max-width: 100%;
    min-width: 100%;
    padding: 1.375rem 0 1.375rem 1.25rem;
  }
}
cx-review-submit .cx-review-cart-item {
  padding: 0;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-review-cart-item .col-md-12 {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  cx-review-submit .cx-review-cart-item .col-md-12 {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-review-cart-item {
    border-style: solid;
    border-width: 1px 0 0;
    border-color: var(--cx-color-light);
  }
}
@media (max-width: 991.98px) {
  cx-review-submit {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-review-submit .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-review-submit .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-review-submit .container {
    width: 100%;
  }
}
cx-review-submit .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-review-submit .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-review-submit .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-review-submit .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-checkout-body.row {
    padding: 0;
  }
}
cx-review-submit .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-review-submit .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-review-submit .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-review-submit .col-md-12 {
    padding: 0 3.5rem 3.5rem 3.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-review-submit .col-md-12 {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }
}

@media (max-width: 1199.98px) {
  cx-place-order {
    --cx-flex-basis: 45%;
  }
}
cx-place-order .scaled-input {
  transform: scale(0.7);
}
cx-place-order .cx-place-order-form {
  font-size: 12px;
  padding: 0;
}
cx-place-order .cx-place-order-form .form-check-input {
  margin: 0;
  top: 1rem;
}
@media (max-width: 991.98px) {
  cx-place-order .cx-place-order-form .form-check-input {
    top: 0.5rem;
  }
}
cx-place-order .cx-place-order-form .form-check-label {
  padding: 0;
  margin-top: 0.3rem;
  margin-inline-end: 0;
  margin-bottom: 0.5rem;
  margin-inline-start: 2rem;
}

@media (max-width: 991.98px) {
  cx-checkout-review-shipping,
  cx-checkout-review-payment,
  cx-checkout-review-overview,
  cx-order-confirmation-shipping {
    background-color: var(--cx-color-inverse);
  }
}
cx-checkout-review-shipping .cx-review-header,
cx-checkout-review-payment .cx-review-header,
cx-checkout-review-overview .cx-review-header,
cx-order-confirmation-shipping .cx-review-header {
  background-color: var(--cx-color-background);
  font-weight: 600;
  font-size: 18px;
  padding: 20px 40px;
  margin: 10px 0 20px 0;
}
cx-checkout-review-shipping .cx-review-summary,
cx-checkout-review-payment .cx-review-summary,
cx-checkout-review-overview .cx-review-summary,
cx-order-confirmation-shipping .cx-review-summary {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--cx-color-inverse);
}
@media (min-width: 992px) {
  cx-checkout-review-shipping .cx-review-summary,
  cx-checkout-review-payment .cx-review-summary,
  cx-checkout-review-overview .cx-review-summary,
  cx-order-confirmation-shipping .cx-review-summary {
    margin-top: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-checkout-review-shipping .cx-review-summary,
  cx-checkout-review-payment .cx-review-summary,
  cx-checkout-review-overview .cx-review-summary,
  cx-order-confirmation-shipping .cx-review-summary {
    flex-direction: column;
    padding: 0;
    margin-top: 32px;
  }
}
cx-checkout-review-shipping .cx-review-summary-wrapper,
cx-checkout-review-payment .cx-review-summary-wrapper,
cx-checkout-review-overview .cx-review-summary-wrapper,
cx-order-confirmation-shipping .cx-review-summary-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  word-break: break-all;
}
cx-checkout-review-shipping .cx-review-summary-edit-step,
cx-checkout-review-payment .cx-review-summary-edit-step,
cx-checkout-review-overview .cx-review-summary-edit-step,
cx-order-confirmation-shipping .cx-review-summary-edit-step {
  padding: 0 20px 20px;
}
@media (max-width: 991.98px) {
  cx-checkout-review-shipping .cx-review-summary-edit-step,
  cx-checkout-review-payment .cx-review-summary-edit-step,
  cx-checkout-review-overview .cx-review-summary-edit-step,
  cx-order-confirmation-shipping .cx-review-summary-edit-step {
    padding: 10px 0 0;
  }
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-payment-card,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-payment-card,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-payment-card,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-payment-card {
  margin: 30px;
  background-color: #ffffff;
  width: 45%;
  flex-grow: 1;
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
}
@media (min-width: 768px) {
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-payment-card:first-child,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-payment-card:first-child,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-payment-card:first-child,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-payment-card:first-child {
    margin-top: 30px;
    margin-inline-start: 0px;
    margin-bottom: 40px;
    margin-inline-end: 15px;
  }
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-payment-card:last-child,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-payment-card:last-child,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-payment-card:last-child,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-payment-card:last-child {
    margin-top: 30px;
    margin-inline-start: 15px;
    margin-bottom: 40px;
    margin-inline-end: 0px;
  }
}
@media (max-width: 767.98px) {
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-payment-card,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-payment-card,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-payment-card,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-payment-card {
    margin-top: 0;
    margin-inline-start: 0;
    margin-bottom: 0;
    margin-inline-end: 0;
    width: 100%;
  }
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card-container,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-card-container,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-card-container,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card-container {
  width: 45%;
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  flex-grow: 1;
}
@media (min-width: 768px) {
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card-container:first-child,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-card-container:first-child,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-card-container:first-child,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card-container:first-child {
    margin-top: 0px;
    margin-inline-start: 0px;
    margin-bottom: 40px;
    margin-inline-end: 15px;
  }
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card-container:last-child,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-card-container:last-child,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-card-container:last-child,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card-container:last-child {
    margin-top: 0px;
    margin-inline-start: 15px;
    margin-bottom: 40px;
    margin-inline-end: 0;
  }
}
@media (max-width: 767.98px) {
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card-container,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-card-container,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-card-container,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card-container {
    margin-top: 0;
    margin-inline-start: 0;
    margin-bottom: 20px;
    margin-inline-end: 0;
    width: 100%;
  }
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-card,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-card,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card:first-child,
  cx-checkout-review-payment .cx-review-summary .cx-review-summary-card:first-child,
  cx-checkout-review-overview .cx-review-summary .cx-review-summary-card:first-child,
  cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card:first-child {
    margin-bottom: 20px;
  }
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card .cx-card-title,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-card .cx-card-title,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-card .cx-card-title,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-bold);
  margin-bottom: 0.5rem;
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card .cx-card-paragraph-title,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-card .cx-card-paragraph-title,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-card .cx-card-paragraph-title,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card .cx-card-paragraph-title {
  font-style: italic;
}
cx-checkout-review-shipping .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step,
cx-checkout-review-payment .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step,
cx-checkout-review-overview .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step,
cx-order-confirmation-shipping .cx-review-summary .cx-review-summary-card .cx-review-summary-edit-step {
  align-self: flex-start;
  padding: 20px;
  font-size: 1.125rem;
}
cx-checkout-review-shipping .cx-review-shipping-summary,
cx-checkout-review-payment .cx-review-shipping-summary,
cx-checkout-review-overview .cx-review-shipping-summary,
cx-order-confirmation-shipping .cx-review-shipping-summary {
  background-color: var(--cx-color-inverse);
}
cx-checkout-review-shipping .cx-review-cart-total,
cx-checkout-review-payment .cx-review-cart-total,
cx-checkout-review-overview .cx-review-cart-total,
cx-order-confirmation-shipping .cx-review-cart-total {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 2rem 0 0.5rem 0;
}
.cx-theme-high-contrast-dark cx-checkout-review-shipping, .cx-theme-high-contrast-light cx-checkout-review-shipping,
.cx-theme-high-contrast-dark cx-checkout-review-payment,
.cx-theme-high-contrast-light cx-checkout-review-payment,
.cx-theme-high-contrast-dark cx-checkout-review-overview,
.cx-theme-high-contrast-light cx-checkout-review-overview,
.cx-theme-high-contrast-dark cx-order-confirmation-shipping,
.cx-theme-high-contrast-light cx-order-confirmation-shipping {
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark cx-checkout-review-shipping .cx-review-summary-payment-card, .cx-theme-high-contrast-light cx-checkout-review-shipping .cx-review-summary-payment-card,
.cx-theme-high-contrast-dark cx-checkout-review-payment .cx-review-summary-payment-card,
.cx-theme-high-contrast-light cx-checkout-review-payment .cx-review-summary-payment-card,
.cx-theme-high-contrast-dark cx-checkout-review-overview .cx-review-summary-payment-card,
.cx-theme-high-contrast-light cx-checkout-review-overview .cx-review-summary-payment-card,
.cx-theme-high-contrast-dark cx-order-confirmation-shipping .cx-review-summary-payment-card,
.cx-theme-high-contrast-light cx-order-confirmation-shipping .cx-review-summary-payment-card {
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark cx-checkout-review-shipping .cx-review-summary, .cx-theme-high-contrast-light cx-checkout-review-shipping .cx-review-summary,
.cx-theme-high-contrast-dark cx-checkout-review-payment .cx-review-summary,
.cx-theme-high-contrast-light cx-checkout-review-payment .cx-review-summary,
.cx-theme-high-contrast-dark cx-checkout-review-overview .cx-review-summary,
.cx-theme-high-contrast-light cx-checkout-review-overview .cx-review-summary,
.cx-theme-high-contrast-dark cx-order-confirmation-shipping .cx-review-summary,
.cx-theme-high-contrast-light cx-order-confirmation-shipping .cx-review-summary {
  background-color: var(--cx-color-background);
}

@media (max-width: 1199.98px) {
  cx-place-order {
    --cx-flex-basis: 45%;
  }
}
cx-place-order .scaled-input {
  transform: scale(0.7);
}
cx-place-order .cx-place-order-form {
  font-size: 12px;
  padding: 0;
}
cx-place-order .cx-place-order-form .form-check-input {
  margin: 0;
  top: 1rem;
}
@media (max-width: 991.98px) {
  cx-place-order .cx-place-order-form .form-check-input {
    top: 0.5rem;
  }
}
cx-place-order .cx-place-order-form .form-check-label {
  padding: 0;
  margin-top: 0.3rem;
  margin-inline-end: 0;
  margin-bottom: 0.5rem;
  margin-inline-start: 2rem;
}

@media (max-width: 991.98px) {
  cx-checkout-review-shipping .cx-review-summary,
  cx-order-confirmation-shipping .cx-review-summary {
    padding: 0px;
  }
}

cx-delivery-address {
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  margin-top: 32px;
  margin-bottom: 30px;
  padding-inline-start: 1.875rem;
  padding-inline-end: 1.875rem;
  padding-top: 1.875rem;
}
@media (max-width: 991.98px) {
  cx-delivery-address {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-delivery-address .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-delivery-address .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-delivery-address .container {
    width: 100%;
  }
}
cx-delivery-address .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-delivery-address .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-delivery-address .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-delivery-address .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-delivery-address .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-delivery-address .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-delivery-address .cx-checkout-body.row {
    padding: 0;
  }
}
cx-delivery-address .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-delivery-address .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-delivery-address .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-delivery-address {
    background-color: var(--cx-color-transparent);
    padding-top: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  cx-delivery-address cx-address-form {
    background-color: var(--cx-color-transparent);
  }
}
cx-delivery-address cx-address-form form {
  background-color: var(--cx-color-transparent);
  padding: 13px 0px 13px 0px;
  margin-bottom: 0;
}
cx-delivery-address .cx-delivery-address-card {
  padding-bottom: 30px;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
@media (min-width: 992px) {
  cx-delivery-address .cx-delivery-address-card:nth-child(odd) {
    padding-inline-end: 15px;
  }
  cx-delivery-address .cx-delivery-address-card:nth-child(even) {
    padding-inline-start: 15px;
  }
}
cx-delivery-address .cx-delivery-address-card .cx-delivery-address-card-inner {
  height: 100%;
  background-color: var(--cx-color-inverse);
  cursor: pointer;
}
cx-delivery-address .cx-checkout-btns-top {
  display: flex;
  justify-content: flex-end;
  padding-inline-start: 1.875rem;
}
@media (max-width: 991.98px) {
  cx-delivery-address .cx-checkout-btns-top {
    display: none;
  }
}
cx-delivery-address .cx-checkout-btns-top .btn {
  width: 50%;
}
cx-delivery-address .cx-checkout-btns-bottom {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  cx-delivery-address .cx-checkout-btns-bottom {
    display: none;
  }
}
cx-delivery-address .cx-checkout-btns-bottom .btn-secondary {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  cx-address-form {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-address-form .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-address-form .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-address-form .container {
    width: 100%;
  }
}
cx-address-form .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-address-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-address-form .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-address-form .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-address-form .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-address-form .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-address-form .cx-checkout-body.row {
    padding: 0;
  }
}
cx-address-form .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-address-form .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-address-form .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
cx-address-form form {
  padding: 13px 32px 41px 32px;
  background-color: var(--cx-color-background);
}
@media (min-width: 992px) {
  cx-address-form form {
    margin-bottom: 3rem;
  }
}

cx-suggested-addresses-dialog .cx-dialog-header {
  padding-top: 1rem;
  padding-inline-end: 1rem;
  padding-bottom: 1rem;
  padding-inline-start: 2rem;
  border-color: var(--cx-color-light);
}
.cx-theme-high-contrast-dark cx-suggested-addresses-dialog .cx-dialog-header, .cx-theme-high-contrast-light cx-suggested-addresses-dialog .cx-dialog-header {
  background-color: var(--cx-color-background);
}
cx-suggested-addresses-dialog .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-suggested-addresses-dialog .cx-dialog-body {
  padding: 1rem;
}
@media (max-width: 767.98px) {
  cx-suggested-addresses-dialog .cx-dialog-body {
    padding: 15px 0;
  }
}
.cx-theme-high-contrast-dark cx-suggested-addresses-dialog .cx-dialog-body, .cx-theme-high-contrast-light cx-suggested-addresses-dialog .cx-dialog-body {
  background-color: var(--cx-color-background);
}
cx-suggested-addresses-dialog .cx-dialog-suggested,
cx-suggested-addresses-dialog .cx-dialog-entered {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  margin-inline-start: 0.75rem;
}
cx-suggested-addresses-dialog .cx-dialog-label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
@media (min-width: 768px) {
  cx-suggested-addresses-dialog .cx-dialog-actions {
    display: flex;
    flex-direction: row;
  }
}
cx-suggested-addresses-dialog .cx-dialog-buttons {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  cx-suggested-addresses-dialog .cx-dialog-buttons + .cx-dialog-buttons {
    margin-inline-start: 0.5rem;
  }
}

cx-delivery-mode {
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  padding: 23px 30px 8px;
  background-color: var(--cx-color-inverse);
}
@media (max-width: 991.98px) {
  cx-delivery-mode {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-delivery-mode .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-delivery-mode .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-delivery-mode .container {
    width: 100%;
  }
}
cx-delivery-mode .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-delivery-mode .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-delivery-mode .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-delivery-mode .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-delivery-mode .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-delivery-mode .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-delivery-mode .cx-checkout-body.row {
    padding: 0;
  }
}
cx-delivery-mode .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-delivery-mode .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-delivery-mode .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-delivery-mode {
    background-color: var(--cx-color-transparent);
    margin-top: 32px;
    padding: 23px 30px 8px;
  }
}
@media (min-width: 992px) {
  cx-delivery-mode {
    margin: 2rem 0 2rem;
  }
}
cx-delivery-mode .cx-delivery-mode-wrapper {
  background-color: var(--cx-color-inverse);
  border: none;
  border-radius: 0px;
  padding: 0;
}
@media (max-width: 991.98px) {
  cx-delivery-mode .cx-delivery-mode-wrapper {
    margin: 0;
  }
}
cx-delivery-mode .form-check {
  display: flex;
  margin-bottom: 0;
}
cx-delivery-mode .form-check .cx-delivery-label {
  padding: 0;
  margin-inline-start: 0.75rem;
  margin-inline-end: auto;
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
cx-delivery-mode .form-check .cx-delivery-label .cx-delivery-mode {
  flex: auto;
  text-align: start;
}
cx-delivery-mode .form-check .cx-delivery-label .cx-delivery-price {
  flex: auto;
  text-align: end;
}
cx-delivery-mode .form-check .cx-delivery-label .cx-delivery-details {
  flex: 100%;
  flex-basis: 100%;
  color: var(--cx-color-success);
}
cx-delivery-mode .form-check .cx-delivery-label .cx-delivery-mode-description {
  width: 100%;
  display: block;
  color: var(--cx-color-success);
}
.cx-theme-high-contrast-dark cx-delivery-mode, .cx-theme-high-contrast-light cx-delivery-mode {
  border: 1px solid var(--cx-color-text);
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark cx-delivery-mode .cx-delivery-mode-wrapper, .cx-theme-high-contrast-light cx-delivery-mode .cx-delivery-mode-wrapper {
  background-color: var(--cx-color-background);
}

@media (max-width: 991.98px) {
  cx-order-confirmation-items .cx-order-items.container {
    max-width: 100%;
    min-width: 100%;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  cx-order-confirmation-items .cx-order-items.container {
    max-width: 100%;
    min-width: 100%;
    padding: 0;
  }
}
cx-order-confirmation-items .cx-order-items-header {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding: 1.375rem 0;
  margin: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
}
@media (max-width: 991.98px) {
  cx-order-confirmation-items .cx-order-items-header {
    max-width: 100%;
    min-width: 100%;
    padding-inline-start: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-order-confirmation-items .cx-order-items-header {
    max-width: 100%;
    min-width: 100%;
    padding-inline-start: 1rem;
  }
}

cx-order-confirmation-thank-you-message .cx-order-confirmation-message {
  text-align: var(--cx-text-align, center);
  padding: var(--cx-padding, 2.5rem);
}
cx-order-confirmation-thank-you-message .cx-order-confirmation-message h2 {
  font-weight: var(--cx-font-weight-bold);
  font-size: var(--cx-font-size, 1.5rem);
}
cx-order-confirmation-thank-you-message .cx-order-confirmation-message .btn-link {
  font-size: 0.875rem;
  font-weight: "bold";
  text-transform: var(--cx-text-transform, uppercase);
}
cx-order-confirmation-thank-you-message .cx-page-title {
  color: var(--cx-color, var(--cx-color-text));
}

cx-order-confirmation-totals .cx-order-summary {
  padding-inline-end: var(--cx-padding, 0);
}
@media (max-width: 991.98px) {
  cx-order-confirmation-totals .cx-order-summary.container {
    max-width: var(--cx-max-width, 100%);
    min-width: var(--cx-min-width, 100%);
    padding-inline-end: var(--cx-padding, 1.625rem);
  }
}
@media (max-width: 767.98px) {
  cx-order-confirmation-totals .cx-order-summary.container {
    padding: var(--cx-padding, 0);
  }
}

cx-guest-register-form .register-guest {
  display: flex;
  justify-content: center;
  background-color: var(--cx-color-background);
  padding: 1.5rem 1rem;
}
@media (min-width: 992px) {
  cx-guest-register-form .register-guest {
    margin: 0 0 2em 0;
  }
}
cx-guest-register-form .register-guest p {
  margin-bottom: 1 rem;
}

cx-promotions .cx-promotions {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-success);
  padding: 0.5rem 0;
}
cx-promotions .cx-promotions ul {
  padding: 0;
}
cx-promotions .cx-promotions p {
  line-height: 1.6;
  font-weight: 700;
}
cx-promotions .cx-promotions li {
  list-style-type: none;
}
cx-promotions .cx-promotions ul > li {
  list-style-type: none;
  line-height: 1.6;
  font-weight: 700;
}

cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
  max-width: var(--cx-page-width-max);
  padding-top: 2rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0;
  padding-inline-start: 1.5rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}
@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
    display: inline-block;
  }
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.TopContent {
  flex: 0 100%;
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.BodyContent {
  flex: 0 63%;
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent {
  flex: 0 0 35%;
  flex-direction: column;
  justify-content: flex-start;
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary {
  flex: unset;
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
  padding-top: 1rem;
  padding-inline-start: 2.5rem;
  padding-bottom: 0;
  padding-inline-end: 0;
}
@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
    padding: 1rem 0 0 0;
  }
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-place-order {
  flex: unset;
  padding-top: 1rem;
  padding-inline-start: 2.5rem;
  padding-bottom: 0;
  padding-inline-end: 0;
}
@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-place-order {
    padding: 1rem 0 0 0;
  }
}
@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent {
    --cx-flex-basis: 50%;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary {
    width: 50%;
    padding-top: 1rem;
  }
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
    padding: 1rem 2.5rem 0;
  }
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-place-order {
    max-width: 50%;
    padding-bottom: 2rem;
  }
}
@media (max-width: 991.98px) and (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
    padding: 1rem 0 0 0;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent {
    --cx-flex-basis: 100%;
    align-items: normal;
  }
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-checkout-order-summary {
    min-width: 100%;
  }
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-place-order {
    max-width: 100%;
  }
}

cx-cost-center {
  padding-top: 2.375rem;
}
@media (max-width: 991.98px) {
  cx-cost-center {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-cost-center .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-cost-center .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-cost-center .container {
    width: 100%;
  }
}
cx-cost-center .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-cost-center .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-cost-center .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-cost-center .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-cost-center .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-cost-center .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-cost-center .cx-checkout-body.row {
    padding: 0;
  }
}
cx-cost-center .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-cost-center .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-cost-center .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-cost-center {
    padding: 20px 0;
  }
}
cx-cost-center.hidden {
  display: none;
}
cx-cost-center select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border-color: var(--cx-color-light);
}
.cx-theme-high-contrast-dark cx-cost-center select, .cx-theme-high-contrast-light cx-cost-center select {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
  border-color: var(--cx-color-text);
}

cx-payment-type {
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  margin-top: 32px;
  margin-bottom: 30px;
  padding-inline-start: 1.875rem;
  padding-inline-end: 1.875rem;
  padding-top: 1.875rem;
}
@media (max-width: 991.98px) {
  cx-payment-type {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-payment-type .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-payment-type .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-payment-type .container {
    width: 100%;
  }
}
cx-payment-type .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-payment-type .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-payment-type .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-payment-type .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-payment-type .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-payment-type .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-payment-type .cx-checkout-body.row {
    padding: 0;
  }
}
cx-payment-type .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-payment-type .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-payment-type .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-payment-type {
    padding-top: 20px;
  }
}
cx-payment-type .cx-payment-type-container {
  padding-top: 20px;
}
cx-payment-type .cx-payment-type-label {
  margin: 0 auto 0 0.75rem;
}
cx-payment-type .cx-payment-type-label .cx-payment-type:hover {
  cursor: pointer;
}

cx-schedule-replenishment-order .scaled-input {
  transform: scale(0.875);
}
cx-schedule-replenishment-order .cx-label-container {
  display: flex;
  margin-bottom: 5px;
}
cx-schedule-replenishment-order .cx-label-container cx-icon {
  font-size: 1.3rem;
}
cx-schedule-replenishment-order .cx-order-replenishment-header {
  margin-inline-end: 14px;
  margin-bottom: 0;
}
.cxFeat_a11yScheduleReplenishment cx-schedule-replenishment-order .cx-order-replenishment-header {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}

cx-schedule-replenishment-order .cx-order-type-card {
  font-size: 0.875rem;
  background-color: var(--cx-color-background);
  border: 1px solid #f1f1f1;
  border-radius: var(--cx-buttons-border-radius);
  margin-bottom: 10px;
  padding: 20px 21px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-order-type-container {
  margin-bottom: 7px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-order-type-container:first-child {
  margin-top: 10px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-order-type-container .form-check-label {
  top: 5px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container {
    margin-inline-start: 2.375rem;
  }
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container:not(:last-child) {
  margin-bottom: 10px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .form-control {
  height: 32px;
  font-size: 0.875rem;
  padding: 0 9px;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .form-data-label {
  align-self: center;
  padding-inline-end: 10px;
  width: 100%;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .form-data-input {
  flex: 1;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .cx-days {
  margin-inline-end: 1.8rem;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .cx-dayMonth {
  margin-inline-start: 1.8rem;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .cx-day-of-month {
  width: 5rem;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .cx-month {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 5.75rem;
}
@media (max-width: 767.98px) {
  cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container .cx-month {
    flex-grow: unset;
    width: 50%;
  }
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container {
  flex-direction: unset;
  flex-wrap: wrap;
  max-width: 280px;
}
@media (max-width: 767.98px) {
  cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container {
    flex-direction: column;
  }
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container .cx-repeat-days {
  flex: 0 100%;
  margin-bottom: 14px;
  align-self: start;
}
.cxFeat_a11yReplenishmentOrderFieldset cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container .cx-repeat-days {
  font-size: inherit;
}

cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container .cx-week-day {
  margin-bottom: 0;
}
cx-schedule-replenishment-order .cx-order-type-card .cx-replenishment-form-data-container.cx-repeat-days-container .form-check {
  flex: 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
cx-schedule-replenishment-order .cx-replenishment-date input {
  padding-inline-start: 18px;
  padding-inline-end: 8px;
}

@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-schedule-replenishment-order {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-schedule-replenishment-order {
    width: 100%;
  }
}
cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-schedule-replenishment-order {
  flex: unset;
  padding-top: 1rem;
  padding-inline-start: 2.5rem;
  padding-bottom: 0;
  padding-inline-end: 0;
}
@media (max-width: 991.98px) {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent cx-schedule-replenishment-order {
    padding: 1rem 0 0 0;
  }
}

cx-service-details {
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  padding: 23px 30px 8px;
  background-color: var(--cx-color-inverse);
}
@media (max-width: 991.98px) {
  cx-service-details {
    display: block;
    background-color: var(--cx-color-transparent);
  }
}
@media (max-width: 991.98px) {
  cx-service-details .col-md-12 {
    padding: 0 0.813rem;
  }
}
@media (max-width: 767.98px) {
  cx-service-details .col-md-12 {
    padding: 0 0.875rem;
  }
}
@media (max-width: 991.98px) {
  cx-service-details .container {
    width: 100%;
  }
}
cx-service-details .cx-checkout-btns {
  padding: 1rem 0;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  cx-service-details .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-service-details .cx-checkout-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-service-details .cx-checkout-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-service-details .cx-checkout-title {
  margin-inline-end: auto;
  margin-inline-start: auto;
  padding-inline-end: 0;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  background-color: var(--cx-color-transparent);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0.438rem;
  margin-bottom: 0.5rem;
  padding-inline-start: 0;
}
cx-service-details .cx-checkout-body {
  display: flex;
  align-items: stretch;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-service-details .cx-checkout-body.row {
    padding: 0;
  }
}
cx-service-details .cx-checkout-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-service-details .cx-checkout-text {
    padding-inline-start: 0;
  }
}
cx-service-details .cx-spinner {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  cx-service-details {
    background-color: var(--cx-color-transparent);
    margin-top: 32px;
    padding: 23px 30px 8px;
  }
}
@media (min-width: 992px) {
  cx-service-details {
    margin: 2rem 0 2rem;
  }
}
cx-service-details.row {
  display: flex;
  flex-direction: column;
}
cx-service-details .cx-service-location-text {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  cx-service-details .cx-service-location-text {
    padding-inline-start: 0;
  }
}

cx-cancel-service-order .form-group {
  position: relative;
  margin-bottom: 1rem;
}
cx-cancel-service-order label[for=reasonTextarea] {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}
cx-cancel-service-order label[for=reasonTextarea] span {
  font-size: 13px;
  font-weight: normal;
  color: #9b9b9b;
}
cx-cancel-service-order #reasonTextarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  resize: none;
}
cx-cancel-service-order #reasonTextarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
cx-cancel-service-order .characters-left {
  position: absolute;
  bottom: -1.5rem;
  right: 0.5rem;
  font-size: 0.875rem;
  color: #6c757d;
}

cx-cancel-service-order-headline .service-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}
cx-cancel-service-order-headline .service-table .service-header {
  margin: 0 0 0.5rem;
  padding: 15px 30px;
  background-color: var(--cx-color-background);
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  border-bottom: 1px solid #dee2e6;
}
cx-cancel-service-order-headline .service-table tr,
cx-cancel-service-order-headline .service-table thead {
  border: none !important;
}
cx-cancel-service-order-headline .service-table th {
  font-weight: bold !important;
  color: #333333;
  text-transform: capitalize !important;
  padding: 9px;
}
cx-cancel-service-order-headline .service-table td {
  padding: 9px;
}
cx-cancel-service-order-headline .service-table,
cx-cancel-service-order-headline .service-cell {
  border: 1px solid #dee2e6;
}

.cx-reschedule-service-form-fields {
  border: 1px solid var(--cx-color-medium);
  border-bottom: 0;
  margin-bottom: -1rem;
}

.cx-section-header {
  background-color: var(--cx-color-background);
  border-bottom: 2px solid var(--cx-color-medium);
}
.cx-section-header h2 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 900;
  padding: 0 1.25rem;
  line-height: 3.5rem;
}

.cx-section-content {
  padding: 1.25rem;
}
.cx-section-content p {
  font-weight: 600;
}

.cx-reschedule-service-actions button {
  margin: 0.5rem 0;
}
.cx-reschedule-service-actions p {
  font-weight: 600;
  margin-top: 1rem;
}

.cx-order-details-actions {
  margin: 1rem -15px 0;
  justify-content: flex-end;
}
.cx-order-details-actions .btn {
  margin-top: 0.6rem;
}