.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

cx-product-multi-dimensional-selector .variant-generic-selector {
  display: flex;
  flex-direction: column;
}
cx-product-multi-dimensional-selector .variant-generic-selector .image-variant-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
cx-product-multi-dimensional-selector .variant-generic-selector .image-variant-container .image-variant {
  background-color: var(--cx-color-transparent);
  border: none;
  cursor: pointer;
  padding: 5px;
  margin: 0 2px 0 0;
}
cx-product-multi-dimensional-selector .variant-generic-selector .image-variant-container .image-variant.active {
  border: 1px solid grey;
}
cx-product-multi-dimensional-selector .variant-generic-selector .image-variant-container cx-media.is-missing {
  min-height: 70px;
  min-width: 70px;
}
cx-product-multi-dimensional-selector .variant-generic-selector .select-variant-container {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  cx-product-multi-dimensional-selector {
    padding-top: 20px;
    padding-inline-end: 20px;
    padding-bottom: 0px;
    padding-inline-start: 20px;
  }
}