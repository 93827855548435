.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog, cx-customer-ticketing-create-dialog .modal.fade .cx-customer-ticket-form-dialog,
.modal.fade cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .modal.fade .cx-customer-ticket-form-dialog,
.modal.fade cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .modal.fade .cx-customer-ticket-form-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog, cx-customer-ticketing-create-dialog .modal.fade .cx-customer-ticket-form-dialog,
  .modal.fade cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-reopen-dialog .modal.fade .cx-customer-ticket-form-dialog,
  .modal.fade cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-close-dialog .modal.fade .cx-customer-ticket-form-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog, cx-customer-ticketing-create-dialog .modal.show .cx-customer-ticket-form-dialog,
.modal.show cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .modal.show .cx-customer-ticket-form-dialog,
.modal.show cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .modal.show .cx-customer-ticket-form-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog, cx-customer-ticketing-create-dialog .modal.modal-static .cx-customer-ticket-form-dialog,
.modal.modal-static cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .modal.modal-static .cx-customer-ticket-form-dialog,
.modal.modal-static cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .modal.modal-static .cx-customer-ticket-form-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container,
.modal-dialog-scrollable cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container,
.modal-dialog-scrollable cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .modal-dialog-scrollable .cx-customer-ticket-form-body,
.modal-dialog-scrollable cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .modal-dialog-scrollable .cx-customer-ticket-form-body,
.modal-dialog-scrollable cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .modal-dialog-scrollable .cx-customer-ticket-form-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog::before,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog::before,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable, cx-customer-ticketing-create-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog,
cx-customer-ticketing-reopen-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog,
cx-customer-ticketing-close-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-customer-ticket-form-container,
.modal-dialog-centered.modal-dialog-scrollable cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-customer-ticket-form-container,
.modal-dialog-centered.modal-dialog-scrollable cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .modal-content, cx-customer-ticketing-create-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .modal-content,
cx-customer-ticketing-reopen-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .modal-content,
cx-customer-ticketing-close-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog .cx-customer-ticket-form-container {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before, cx-customer-ticketing-create-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog::before,
cx-customer-ticketing-reopen-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog::before,
cx-customer-ticketing-close-dialog .modal-dialog-scrollable.cx-customer-ticket-form-dialog::before {
  content: none;
}

.modal-content, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container,
  .modal-dialog-scrollable cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
  cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container,
  .modal-dialog-scrollable cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
  cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .modal-dialog-scrollable .cx-customer-ticket-form-container {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog,
  cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog::before,
  cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog::before,
  cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: "bold";
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.cx-theme-high-contrast-dark .modal-header, .cx-theme-high-contrast-light .modal-header {
  background-color: var(--cx-color-background);
}
.modal-body, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container {
  border-radius: 0;
  border: none;
}
.cx-theme-high-contrast-dark .modal-content, .cx-theme-high-contrast-dark cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container,
.cx-theme-high-contrast-dark cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container,
.cx-theme-high-contrast-dark cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container, .cx-theme-high-contrast-light .modal-content, .cx-theme-high-contrast-light cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container,
.cx-theme-high-contrast-light cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container,
.cx-theme-high-contrast-light cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .modal-content .close, .cx-theme-high-contrast-dark cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-dark cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-dark cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-dark .cx-customer-ticket-form-container .close, .cx-theme-high-contrast-light .modal-content .close, .cx-theme-high-contrast-light cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close, cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-light cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-light cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-theme-high-contrast-light .cx-customer-ticket-form-container .close {
  color: var(--cx-color-text);
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.cxFeat_a11yExpandedFocusIndicator .modal .cx-dialog-header button.close,
.cxFeat_a11yExpandedFocusIndicator .modal button.close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}
.cx-theme-high-contrast-dark .cx-modal-content, .cx-theme-high-contrast-light .cx-modal-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .cx-modal-content .close, .cx-theme-high-contrast-light .cx-modal-content .close {
  color: var(--cx-color-text);
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

cx-customer-ticketing-close,
cx-customer-ticketing-reopen {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-close div,
  cx-customer-ticketing-reopen div {
    justify-content: center;
    display: flex;
  }
}
cx-customer-ticketing-close button,
cx-customer-ticketing-reopen button {
  margin: 1rem;
}

cx-customer-ticketing-create {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-create {
    width: 100%;
  }
  cx-customer-ticketing-create .cx-customer-ticketing-create {
    width: 100%;
  }
  cx-customer-ticketing-create div {
    justify-content: center;
    display: flex;
  }
}

cx-customer-ticketing-details .cx-ticket-details {
  background-color: var(--cx-color-background);
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-details .cx-ticket-details {
    background-color: var(--cx-color-inverse);
  }
}
cx-customer-ticketing-details .cx-ticket-details .container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}
@media (max-width: 991.98px) {
  cx-customer-ticketing-details .cx-ticket-details .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-details .cx-ticket-details .container {
    flex-direction: column;
    padding: 1.25rem;
  }
}
cx-customer-ticketing-details .cx-ticket-details .container .cx-details-card {
  flex: 1;
  padding: 0 15px;
}
@media (min-width: 992px) {
  cx-customer-ticketing-details .cx-ticket-details .container .cx-details-card:not(:last-of-type) {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
@media (max-width: 991.98px) {
  cx-customer-ticketing-details .cx-ticket-details .container .cx-details-card {
    flex: 0 0 33%;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-details .cx-ticket-details .container .cx-details-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-customer-ticketing-details .cx-ticket-details .container .cx-details-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

cx-customer-ticketing-create-dialog,
cx-customer-ticketing-reopen-dialog,
cx-customer-ticketing-close-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-header .cx-customer-ticket-form-title,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-header .cx-customer-ticket-form-title,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-header .cx-customer-ticket-form-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label {
  font-weight: bold;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-input-hint,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-input-hint,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-input-hint {
  text-align: end;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label-optional,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label-optional,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-form-row .cx-customer-ticket-label-optional {
  font-weight: normal;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-input-hint,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-input-hint,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-input-hint {
  font-size: 0.75rem;
  font-style: italic;
  text-align: end;
  margin-bottom: 0;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-file-upload-hint,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-file-upload-hint,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-body .cx-customer-ticket-file-upload-hint {
  font-size: 0.75rem;
  font-style: italic;
  text-align: start;
  margin-bottom: 0;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload {
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: start;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload .cx-message-footer-text,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload .cx-message-footer-text,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload .cx-message-footer-text {
  text-decoration: underline;
  cursor: pointer;
  padding: 0rem 0.5rem;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload cx-icon,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload cx-icon,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload cx-icon {
  color: #a1aabf;
  cursor: pointer;
  font-size: 1.25rem;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload p,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload p,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container cx-file-upload p {
  margin-bottom: 0;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer {
  display: flex;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:first-child,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:first-child,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:last-child,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:last-child,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
.cxFeat_a11yImproveContrast cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
.cxFeat_a11yImproveContrast cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
.cxFeat_a11yVisibleFocusOverflows cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
.cxFeat_a11yVisibleFocusOverflows cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .cx-customer-ticket-form-footer button:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

.cx-theme-high-contrast-dark cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container, .cx-theme-high-contrast-light cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
.cx-theme-high-contrast-dark cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
.cx-theme-high-contrast-light cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
.cx-theme-high-contrast-dark cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container,
.cx-theme-high-contrast-light cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close, .cx-theme-high-contrast-light cx-customer-ticketing-create-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-dark cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-light cx-customer-ticketing-reopen-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-dark cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close,
.cx-theme-high-contrast-light cx-customer-ticketing-close-dialog .cx-customer-ticket-form-dialog .cx-customer-ticket-form-container .close {
  color: var(--cx-color-text);
}

cx-customer-ticketing-list .cx-ticketing-list-title-text {
  font-size: var(--cx-font-size, 1.4rem);
}
cx-customer-ticketing-list .cx-ticketing-list-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-top: 1px solid var(--cx-color-light);
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-table {
    display: flex;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-table tbody {
    display: flex;
    padding: 0;
    flex-direction: column;
    width: 100%;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-table tr:first-child {
  padding-top: 0;
}
cx-customer-ticketing-list .cx-ticketing-list-table th {
  padding: 1.5rem 1.25rem 1.125rem 1.25rem;
  text-align: start;
}
cx-customer-ticketing-list .cx-ticketing-list-table th:last-child {
  text-align: end;
}
cx-customer-ticketing-list .cx-ticketing-list-table td {
  padding: 1.625rem 1.25rem;
  font-size: var(--cx-font-size, 1rem);
}
cx-customer-ticketing-list .cx-ticketing-list-table td a {
  min-height: 0px;
  min-width: 0px;
}
@media (min-width: 768px) {
  cx-customer-ticketing-list .cx-ticketing-list-table td {
    text-align: start;
  }
  cx-customer-ticketing-list .cx-ticketing-list-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-customer-ticketing-list .cx-ticketing-list-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-customer-ticketing-list .cx-ticketing-list-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-thead-mobile {
    display: none;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-label {
  color: var(--cx-color-secondary);
}
@media (min-width: 768px) {
  cx-customer-ticketing-list .cx-ticketing-list-label {
    display: none;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    min-width: 140px;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-id {
  text-decoration: underline;
}
cx-customer-ticketing-list .cx-ticketing-list-value {
  color: var(--cx-color-text);
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  hyphens: auto;
}
@media (min-width: 576px) {
  cx-customer-ticketing-list .cx-ticketing-list-value {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: "normal";
    white-space: nowrap;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-data {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-data {
    text-align: start;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-sort {
  display: flex;
  margin: 0;
}
cx-customer-ticketing-list .cx-ticketing-list-sort.top {
  justify-content: space-between;
  padding: 1rem 0;
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-sort.top {
    flex-wrap: wrap;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-sort.bottom {
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
}
cx-customer-ticketing-list .cx-ticketing-list-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
@media (max-width: 767.98px) {
  cx-customer-ticketing-list .cx-ticketing-list-form-group {
    width: 100%;
    margin-bottom: 17px;
  }
}
cx-customer-ticketing-list .cx-ticketing-list-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-customer-ticketing-list .cx-ticketing-list-form-group cx-sorting {
  width: 100%;
}
cx-customer-ticketing-list .cx-text-green {
  color: var(--cx-color-success);
  font-weight: var(--cx-font-weight-bold);
}
cx-customer-ticketing-list .cx-text-grey {
  color: var(--cx-color-secondary);
}

cx-my-account-v2-customer-ticketing {
  margin: 1rem 0rem;
  border: 1px solid var(--cx-color-medium);
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-header .cx-my-account-customer-ticket-heading {
  font-size: 16px;
  font-weight: 700;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-header .cx-my-account-customer-ticket-show-more {
  font-size: 14px;
  font-weight: 600;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-body {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem 1rem 1rem;
  padding: 15px 30px;
  background-color: var(--cx-color-light);
  border: 1px solid var(--cx-color-medium);
  border-radius: 0;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-body .cx-my-account-customer-ticket-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-body .cx-my-account-customer-ticket-subject {
  font-size: 16px;
  font-weight: 700;
}
cx-my-account-v2-customer-ticketing .cx-my-account-customer-ticket-body .cx-my-account-customer-ticket-details-light {
  font-size: 12px;
  font-weight: 400;
  color: var(--cx-color-secondary);
}
cx-my-account-v2-customer-ticketing .cx-my-account-no-ticket {
  margin: 1rem;
  padding: 15px;
}