@charset "UTF-8";
@import '@fontsource/open-sans/300.css';
@import '@fontsource/open-sans/400.css';
@import '@fontsource/open-sans/600.css';
@import '@fontsource/open-sans/700.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import '@ng-select/ng-select/themes/default.theme.css';
.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: "normal";
  line-height: 1.6;
  color: #14293a;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: "bold";
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1f7bc0;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #14517e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.375rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2222222222;
}

h1, .h1 {
  font-size: 1.85rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.375rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875rem;
  font-weight: "normal";
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-background {
  background-color: #f4f4f4 !important;
}

a.bg-background:hover, a.bg-background:focus,
button.bg-background:hover,
button.bg-background:focus {
  background-color: #dbdbdb !important;
}

.bg-text {
  background-color: #14293a !important;
}

a.bg-text:hover, a.bg-text:focus,
button.bg-text:hover,
button.bg-text:focus {
  background-color: #070e14 !important;
}

.bg-inverse {
  background-color: #ffffff !important;
}

a.bg-inverse:hover, a.bg-inverse:focus,
button.bg-inverse:hover,
button.bg-inverse:focus {
  background-color: #e6e6e6 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

a.bg-transparent:hover, a.bg-transparent:focus,
button.bg-transparent:hover,
button.bg-transparent:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-primary {
  background-color: #1f7bc0 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #185f94 !important;
}

.bg-secondary {
  background-color: #6c7079 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #54575e !important;
}

.bg-success {
  background-color: #38871f !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #275e15 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #db0002 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a80002 !important;
}

.bg-light {
  background-color: #f1f1f1 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d8d8d8 !important;
}

.bg-dark {
  background-color: #6c7079 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #54575e !important;
}

.bg-primary-accent {
  background-color: #055f9f !important;
}

a.bg-primary-accent:hover, a.bg-primary-accent:focus,
button.bg-primary-accent:hover,
button.bg-primary-accent:focus {
  background-color: #03416e !important;
}

.bg-success-accent {
  background-color: #f0fbe4 !important;
}

a.bg-success-accent:hover, a.bg-success-accent:focus,
button.bg-success-accent:hover,
button.bg-success-accent:focus {
  background-color: #d7f4b8 !important;
}

.bg-danger-accent {
  background-color: #fff1f1 !important;
}

a.bg-danger-accent:hover, a.bg-danger-accent:focus,
button.bg-danger-accent:hover,
button.bg-danger-accent:focus {
  background-color: #ffbebe !important;
}

.bg-warning-accent {
  background-color: #fff5df !important;
}

a.bg-warning-accent:hover, a.bg-warning-accent:focus,
button.bg-warning-accent:hover,
button.bg-warning-accent:focus {
  background-color: #ffe5ac !important;
}

.bg-info-accent {
  background-color: #deeffe !important;
}

a.bg-info-accent:hover, a.bg-info-accent:focus,
button.bg-info-accent:hover,
button.bg-info-accent:focus {
  background-color: #add7fd !important;
}

.bg-medium {
  background-color: #d3d6db !important;
}

a.bg-medium:hover, a.bg-medium:focus,
button.bg-medium:hover,
button.bg-medium:focus {
  background-color: #b7bcc4 !important;
}

.bg-background-dark {
  background-color: #212738 !important;
}

a.bg-background-dark:hover, a.bg-background-dark:focus,
button.bg-background-dark:hover,
button.bg-background-dark:focus {
  background-color: #0e1118 !important;
}

.bg-visual-focus {
  background-color: #6d9df7 !important;
}

a.bg-visual-focus:hover, a.bg-visual-focus:focus,
button.bg-visual-focus:hover,
button.bg-visual-focus:focus {
  background-color: #3d7df4 !important;
}

.bg-background-focus {
  background-color: rgba(80, 176, 244, 0.1) !important;
}

a.bg-background-focus:hover, a.bg-background-focus:focus,
button.bg-background-focus:hover,
button.bg-background-focus:focus {
  background-color: rgba(32, 154, 241, 0.1) !important;
}

.bg-border-focus {
  background-color: rgba(166, 204, 232, 0.6) !important;
}

a.bg-border-focus:hover, a.bg-border-focus:focus,
button.bg-border-focus:hover,
button.bg-border-focus:focus {
  background-color: rgba(125, 181, 222, 0.6) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-background {
  border-color: #f4f4f4 !important;
}

.border-text {
  border-color: #14293a !important;
}

.border-inverse {
  border-color: #ffffff !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-primary {
  border-color: #1f7bc0 !important;
}

.border-secondary {
  border-color: #6c7079 !important;
}

.border-success {
  border-color: #38871f !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #db0002 !important;
}

.border-light {
  border-color: #f1f1f1 !important;
}

.border-dark {
  border-color: #6c7079 !important;
}

.border-primary-accent {
  border-color: #055f9f !important;
}

.border-success-accent {
  border-color: #f0fbe4 !important;
}

.border-danger-accent {
  border-color: #fff1f1 !important;
}

.border-warning-accent {
  border-color: #fff5df !important;
}

.border-info-accent {
  border-color: #deeffe !important;
}

.border-medium {
  border-color: #d3d6db !important;
}

.border-background-dark {
  border-color: #212738 !important;
}

.border-visual-focus {
  border-color: #6d9df7 !important;
}

.border-background-focus {
  border-color: rgba(80, 176, 244, 0.1) !important;
}

.border-border-focus {
  border-color: rgba(166, 204, 232, 0.6) !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: "light" !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: "normal" !important;
}

.font-weight-bold {
  font-weight: "bold" !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-background {
  color: #f4f4f4 !important;
}

a.text-background:hover, a.text-background:focus {
  color: #cecece !important;
}

.text-text {
  color: #14293a !important;
}

a.text-text:hover, a.text-text:focus {
  color: #000101 !important;
}

.text-inverse {
  color: #ffffff !important;
}

a.text-inverse:hover, a.text-inverse:focus {
  color: #d9d9d9 !important;
}

.text-transparent {
  color: transparent !important;
}

a.text-transparent:hover, a.text-transparent:focus {
  color: rgba(0, 0, 0, 0) !important;
}

.text-primary {
  color: #1f7bc0 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #14517e !important;
}

.text-secondary {
  color: #6c7079 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #484b51 !important;
}

.text-success {
  color: #38871f !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e4911 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #db0002 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #8f0001 !important;
}

.text-light {
  color: #f1f1f1 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbcbcb !important;
}

.text-dark {
  color: #6c7079 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #484b51 !important;
}

.text-primary-accent {
  color: #055f9f !important;
}

a.text-primary-accent:hover, a.text-primary-accent:focus {
  color: #033355 !important;
}

.text-success-accent {
  color: #f0fbe4 !important;
}

a.text-success-accent:hover, a.text-success-accent:focus {
  color: #cbf1a1 !important;
}

.text-danger-accent {
  color: #fff1f1 !important;
}

a.text-danger-accent:hover, a.text-danger-accent:focus {
  color: #ffa5a5 !important;
}

.text-warning-accent {
  color: #fff5df !important;
}

a.text-warning-accent:hover, a.text-warning-accent:focus {
  color: #ffdd93 !important;
}

.text-info-accent {
  color: #deeffe !important;
}

a.text-info-accent:hover, a.text-info-accent:focus {
  color: #94cbfc !important;
}

.text-medium {
  color: #d3d6db !important;
}

a.text-medium:hover, a.text-medium:focus {
  color: #a9afb9 !important;
}

.text-background-dark {
  color: #212738 !important;
}

a.text-background-dark:hover, a.text-background-dark:focus {
  color: #050508 !important;
}

.text-visual-focus {
  color: #6d9df7 !important;
}

a.text-visual-focus:hover, a.text-visual-focus:focus {
  color: #246cf3 !important;
}

.text-background-focus {
  color: rgba(80, 176, 244, 0.1) !important;
}

a.text-background-focus:hover, a.text-background-focus:focus {
  color: rgba(15, 142, 233, 0.1) !important;
}

.text-border-focus {
  color: rgba(166, 204, 232, 0.6) !important;
}

a.text-border-focus:hover, a.text-border-focus:focus {
  color: rgba(105, 169, 216, 0.6) !important;
}

.text-body {
  color: #14293a !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: "normal";
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1f7bc0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1f7bc0;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

:root {
  --cx-spatial-base: 0.5rem;
  --cx-spatial-sm: calc(var(--cx-spatial-base) / 2);
  --cx-spatial-md: calc(2 * var(--cx-spatial-base));
  --cx-spatial-lg: calc(4 * var(--cx-spatial-base));
  --cx-spatial-xl: calc(8 * var(--cx-spatial-base));
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.btn {
  display: inline-block;
  font-weight: "normal";
  color: #14293a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 14px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #14293a;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-background {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-background:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
.btn-background:focus, .btn-background.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
.btn-background.disabled, .btn-background:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-background:not(:disabled):not(.disabled):active, .btn-background:not(:disabled):not(.disabled).active, .show > .btn-background.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
.btn-background:not(:disabled):not(.disabled):active:focus, .btn-background:not(:disabled):not(.disabled).active:focus, .show > .btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}

.btn-text {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.btn-text:hover {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
}
.btn-text:focus, .btn-text.focus {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
.btn-text.disabled, .btn-text:disabled {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.btn-text:not(:disabled):not(.disabled):active, .btn-text:not(:disabled):not(.disabled).active, .show > .btn-text.dropdown-toggle {
  color: #fff;
  background-color: #070e14;
  border-color: #04070b;
}
.btn-text:not(:disabled):not(.disabled):active:focus, .btn-text:not(:disabled):not(.disabled).active:focus, .show > .btn-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}

.btn-inverse {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-inverse:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-inverse:focus, .btn-inverse.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-inverse.disabled, .btn-inverse:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active, .show > .btn-inverse.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus, .show > .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-transparent:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.btn-transparent:focus, .btn-transparent.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-transparent.disabled, .btn-transparent:disabled {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-transparent:not(:disabled):not(.disabled):active, .btn-transparent:not(:disabled):not(.disabled).active, .show > .btn-transparent.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.btn-transparent:not(:disabled):not(.disabled):active:focus, .btn-transparent:not(:disabled):not(.disabled).active:focus, .show > .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-primary {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #185f94;
  border-color: #165889;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.btn-success:hover {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #275e15;
  border-color: #225313;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a80002;
  border-color: #9b0001;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.btn-light:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d8d8d8;
  border-color: #d1d1d1;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-dark:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}

.btn-primary-accent {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.btn-primary-accent:hover {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
}
.btn-primary-accent:focus, .btn-primary-accent.focus {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
.btn-primary-accent.disabled, .btn-primary-accent:disabled {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.btn-primary-accent:not(:disabled):not(.disabled):active, .btn-primary-accent:not(:disabled):not(.disabled).active, .show > .btn-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #03416e;
  border-color: #033a61;
}
.btn-primary-accent:not(:disabled):not(.disabled):active:focus, .btn-primary-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}

.btn-success-accent {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.btn-success-accent:hover {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
}
.btn-success-accent:focus, .btn-success-accent.focus {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
.btn-success-accent.disabled, .btn-success-accent:disabled {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.btn-success-accent:not(:disabled):not(.disabled):active, .btn-success-accent:not(:disabled):not(.disabled).active, .show > .btn-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #d7f4b8;
  border-color: #d1f3ac;
}
.btn-success-accent:not(:disabled):not(.disabled):active:focus, .btn-success-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}

.btn-danger-accent {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.btn-danger-accent:hover {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
}
.btn-danger-accent:focus, .btn-danger-accent.focus {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
.btn-danger-accent.disabled, .btn-danger-accent:disabled {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.btn-danger-accent:not(:disabled):not(.disabled):active, .btn-danger-accent:not(:disabled):not(.disabled).active, .show > .btn-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffbebe;
  border-color: #ffb1b1;
}
.btn-danger-accent:not(:disabled):not(.disabled):active:focus, .btn-danger-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}

.btn-warning-accent {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.btn-warning-accent:hover {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
}
.btn-warning-accent:focus, .btn-warning-accent.focus {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
.btn-warning-accent.disabled, .btn-warning-accent:disabled {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.btn-warning-accent:not(:disabled):not(.disabled):active, .btn-warning-accent:not(:disabled):not(.disabled).active, .show > .btn-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffe5ac;
  border-color: #ffe19f;
}
.btn-warning-accent:not(:disabled):not(.disabled):active:focus, .btn-warning-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}

.btn-info-accent {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.btn-info-accent:hover {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
}
.btn-info-accent:focus, .btn-info-accent.focus {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
.btn-info-accent.disabled, .btn-info-accent:disabled {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.btn-info-accent:not(:disabled):not(.disabled):active, .btn-info-accent:not(:disabled):not(.disabled).active, .show > .btn-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #add7fd;
  border-color: #a0d1fc;
}
.btn-info-accent:not(:disabled):not(.disabled):active:focus, .btn-info-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}

.btn-medium {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.btn-medium:hover {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
}
.btn-medium:focus, .btn-medium.focus {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
.btn-medium.disabled, .btn-medium:disabled {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.btn-medium:not(:disabled):not(.disabled):active, .btn-medium:not(:disabled):not(.disabled).active, .show > .btn-medium.dropdown-toggle {
  color: #212529;
  background-color: #b7bcc4;
  border-color: #b0b5be;
}
.btn-medium:not(:disabled):not(.disabled):active:focus, .btn-medium:not(:disabled):not(.disabled).active:focus, .show > .btn-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}

.btn-background-dark {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.btn-background-dark:hover {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
}
.btn-background-dark:focus, .btn-background-dark.focus {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
.btn-background-dark.disabled, .btn-background-dark:disabled {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.btn-background-dark:not(:disabled):not(.disabled):active, .btn-background-dark:not(:disabled):not(.disabled).active, .show > .btn-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #0e1118;
  border-color: #090b10;
}
.btn-background-dark:not(:disabled):not(.disabled):active:focus, .btn-background-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}

.btn-visual-focus {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.btn-visual-focus:hover {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
}
.btn-visual-focus:focus, .btn-visual-focus.focus {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
.btn-visual-focus.disabled, .btn-visual-focus:disabled {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.btn-visual-focus:not(:disabled):not(.disabled):active, .btn-visual-focus:not(:disabled):not(.disabled).active, .show > .btn-visual-focus.dropdown-toggle {
  color: #fff;
  background-color: #3d7df4;
  border-color: #3174f4;
}
.btn-visual-focus:not(:disabled):not(.disabled):active:focus, .btn-visual-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}

.btn-background-focus {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.btn-background-focus:hover {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
}
.btn-background-focus:focus, .btn-background-focus.focus {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
.btn-background-focus.disabled, .btn-background-focus:disabled {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.btn-background-focus:not(:disabled):not(.disabled):active, .btn-background-focus:not(:disabled):not(.disabled).active, .show > .btn-background-focus.dropdown-toggle {
  color: #fff;
  background-color: rgba(32, 154, 241, 0.1);
  border-color: rgba(20, 149, 240, 0.1);
}
.btn-background-focus:not(:disabled):not(.disabled):active:focus, .btn-background-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}

.btn-border-focus {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.btn-border-focus:hover {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
}
.btn-border-focus:focus, .btn-border-focus.focus {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
.btn-border-focus.disabled, .btn-border-focus:disabled {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.btn-border-focus:not(:disabled):not(.disabled):active, .btn-border-focus:not(:disabled):not(.disabled).active, .show > .btn-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(125, 181, 222, 0.6);
  border-color: rgba(115, 175, 219, 0.6);
}
.btn-border-focus:not(:disabled):not(.disabled):active:focus, .btn-border-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}

.btn-outline-background {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-background:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-background:focus, .btn-outline-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
.btn-outline-background.disabled, .btn-outline-background:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
.btn-outline-background:not(:disabled):not(.disabled):active, .btn-outline-background:not(:disabled):not(.disabled).active, .show > .btn-outline-background.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.btn-outline-background:not(:disabled):not(.disabled):active:focus, .btn-outline-background:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-text {
  color: #14293a;
  border-color: #14293a;
}
.btn-outline-text:hover {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.btn-outline-text:focus, .btn-outline-text.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
.btn-outline-text.disabled, .btn-outline-text:disabled {
  color: #14293a;
  background-color: transparent;
}
.btn-outline-text:not(:disabled):not(.disabled):active, .btn-outline-text:not(:disabled):not(.disabled).active, .show > .btn-outline-text.dropdown-toggle {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.btn-outline-text:not(:disabled):not(.disabled):active:focus, .btn-outline-text:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}

.btn-outline-inverse {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-inverse:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-inverse:focus, .btn-outline-inverse.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active, .show > .btn-outline-inverse.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-inverse:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-transparent {
  color: transparent;
  border-color: transparent;
}
.btn-outline-transparent:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-outline-transparent:focus, .btn-outline-transparent.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-transparent.disabled, .btn-outline-transparent:disabled {
  color: transparent;
  background-color: transparent;
}
.btn-outline-transparent:not(:disabled):not(.disabled):active, .btn-outline-transparent:not(:disabled):not(.disabled).active, .show > .btn-outline-transparent.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-outline-transparent:not(:disabled):not(.disabled):active:focus, .btn-outline-transparent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary {
  color: #1f7bc0;
  border-color: #1f7bc0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1f7bc0;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}

.btn-outline-secondary {
  color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c7079;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}

.btn-outline-success {
  color: #38871f;
  border-color: #38871f;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #38871f;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #db0002;
  border-color: #db0002;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #db0002;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}

.btn-outline-light {
  color: #f1f1f1;
  border-color: #f1f1f1;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f1f1f1;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}

.btn-outline-dark {
  color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #6c7079;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}

.btn-outline-primary-accent {
  color: #055f9f;
  border-color: #055f9f;
}
.btn-outline-primary-accent:hover {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.btn-outline-primary-accent:focus, .btn-outline-primary-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
.btn-outline-primary-accent.disabled, .btn-outline-primary-accent:disabled {
  color: #055f9f;
  background-color: transparent;
}
.btn-outline-primary-accent:not(:disabled):not(.disabled):active, .btn-outline-primary-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.btn-outline-primary-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}

.btn-outline-success-accent {
  color: #f0fbe4;
  border-color: #f0fbe4;
}
.btn-outline-success-accent:hover {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.btn-outline-success-accent:focus, .btn-outline-success-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
.btn-outline-success-accent.disabled, .btn-outline-success-accent:disabled {
  color: #f0fbe4;
  background-color: transparent;
}
.btn-outline-success-accent:not(:disabled):not(.disabled):active, .btn-outline-success-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.btn-outline-success-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-success-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}

.btn-outline-danger-accent {
  color: #fff1f1;
  border-color: #fff1f1;
}
.btn-outline-danger-accent:hover {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.btn-outline-danger-accent:focus, .btn-outline-danger-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
.btn-outline-danger-accent.disabled, .btn-outline-danger-accent:disabled {
  color: #fff1f1;
  background-color: transparent;
}
.btn-outline-danger-accent:not(:disabled):not(.disabled):active, .btn-outline-danger-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.btn-outline-danger-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-danger-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}

.btn-outline-warning-accent {
  color: #fff5df;
  border-color: #fff5df;
}
.btn-outline-warning-accent:hover {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.btn-outline-warning-accent:focus, .btn-outline-warning-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
.btn-outline-warning-accent.disabled, .btn-outline-warning-accent:disabled {
  color: #fff5df;
  background-color: transparent;
}
.btn-outline-warning-accent:not(:disabled):not(.disabled):active, .btn-outline-warning-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.btn-outline-warning-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-warning-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}

.btn-outline-info-accent {
  color: #deeffe;
  border-color: #deeffe;
}
.btn-outline-info-accent:hover {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.btn-outline-info-accent:focus, .btn-outline-info-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
.btn-outline-info-accent.disabled, .btn-outline-info-accent:disabled {
  color: #deeffe;
  background-color: transparent;
}
.btn-outline-info-accent:not(:disabled):not(.disabled):active, .btn-outline-info-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.btn-outline-info-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-info-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}

.btn-outline-medium {
  color: #d3d6db;
  border-color: #d3d6db;
}
.btn-outline-medium:hover {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.btn-outline-medium:focus, .btn-outline-medium.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
.btn-outline-medium.disabled, .btn-outline-medium:disabled {
  color: #d3d6db;
  background-color: transparent;
}
.btn-outline-medium:not(:disabled):not(.disabled):active, .btn-outline-medium:not(:disabled):not(.disabled).active, .show > .btn-outline-medium.dropdown-toggle {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.btn-outline-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-medium:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}

.btn-outline-background-dark {
  color: #212738;
  border-color: #212738;
}
.btn-outline-background-dark:hover {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.btn-outline-background-dark:focus, .btn-outline-background-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
.btn-outline-background-dark.disabled, .btn-outline-background-dark:disabled {
  color: #212738;
  background-color: transparent;
}
.btn-outline-background-dark:not(:disabled):not(.disabled):active, .btn-outline-background-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.btn-outline-background-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-background-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}

.btn-outline-visual-focus {
  color: #6d9df7;
  border-color: #6d9df7;
}
.btn-outline-visual-focus:hover {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.btn-outline-visual-focus:focus, .btn-outline-visual-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
.btn-outline-visual-focus.disabled, .btn-outline-visual-focus:disabled {
  color: #6d9df7;
  background-color: transparent;
}
.btn-outline-visual-focus:not(:disabled):not(.disabled):active, .btn-outline-visual-focus:not(:disabled):not(.disabled).active, .show > .btn-outline-visual-focus.dropdown-toggle {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.btn-outline-visual-focus:not(:disabled):not(.disabled):active:focus, .btn-outline-visual-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}

.btn-outline-background-focus {
  color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.btn-outline-background-focus:hover {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.btn-outline-background-focus:focus, .btn-outline-background-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
.btn-outline-background-focus.disabled, .btn-outline-background-focus:disabled {
  color: rgba(80, 176, 244, 0.1);
  background-color: transparent;
}
.btn-outline-background-focus:not(:disabled):not(.disabled):active, .btn-outline-background-focus:not(:disabled):not(.disabled).active, .show > .btn-outline-background-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.btn-outline-background-focus:not(:disabled):not(.disabled):active:focus, .btn-outline-background-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}

.btn-outline-border-focus {
  color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.btn-outline-border-focus:hover {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.btn-outline-border-focus:focus, .btn-outline-border-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
.btn-outline-border-focus.disabled, .btn-outline-border-focus:disabled {
  color: rgba(166, 204, 232, 0.6);
  background-color: transparent;
}
.btn-outline-border-focus:not(:disabled):not(.disabled):active, .btn-outline-border-focus:not(:disabled):not(.disabled).active, .show > .btn-outline-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.btn-outline-border-focus:not(:disabled):not(.disabled):active:focus, .btn-outline-border-focus:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}

.btn-link {
  font-weight: "normal";
  color: #1f7bc0;
  text-decoration: none;
}
.btn-link:hover {
  color: #14517e;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

button:focus {
  outline: none;
}
button.link {
  background-color: transparent;
  border-style: none;
  padding: 0;
  cursor: pointer;
}
button.link:disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
}

.btn {
  font-size: 1.188rem;
  font-weight: var(--cx-font-weight-bold);
  text-transform: var(--cx-text-transform);
  line-height: 1;
  padding: 12px 24px;
  min-height: 48px;
  min-width: 48px;
  border-radius: var(--cx-buttons-border-radius);
}
.cxFeat_a11yImproveContrast .btn {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}

.btn-sm {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  max-height: 40px;
  min-width: 40px;
}
.btn-lg {
  height: 55px;
  max-height: 55px;
  min-width: 55px;
}
.btn-primary {
  background-color: var(--cx-color-primary);
  border: 3px solid var(--cx-color-primary);
}
.btn-primary:hover {
  background-color: var(--cx-color-primary-accent);
  border-color: var(--cx-color-primary-accent);
}
.btn-primary.active {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  -webkit-filter: brightness(calc(88 / 100));
  filter: brightness(calc(88 / 100));
}
.cxFeat_a11yImproveContrast .btn-primary.active {
  filter: none;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: var(--cx-color-border-focus);
  border: var(--cx-color-border-focus);
}
.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  border-color: var(--cx-color-border-focus);
}
.btn-primary:focus {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-inverse);
  box-shadow: 0 0 0 0.2rem var(--cx-color-primary);
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  -webkit-filter: brightness(calc(88 / 10));
  filter: brightness(calc(88 / 10));
}
.cxFeat_a11yImproveContrast .btn-primary:not(:disabled):not(.disabled):active, .cxFeat_a11yImproveContrast .btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-primary-accent);
  filter: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--cx-color-primary), 0.5);
}
.cx-theme-high-contrast-dark .btn-primary {
  color: var(--cx-color-medium);
}
.cx-theme-high-contrast-dark .btn-primary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .btn-primary:not(:disabled):not(.disabled).active {
  color: var(--cx-color-medium);
}
.btn-secondary {
  color: var(--cx-color-primary);
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary);
  border-width: var(--cx-buttons-border-width);
}
.btn-secondary:hover {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary-accent);
  color: var(--cx-color-primary-accent);
}
.btn-secondary.active {
  background-color: var(--cx-color-secondary);
  border-color: var(--cx-color-secondary);
  -webkit-filter: brightness(calc(84 / 100));
  filter: brightness(calc(84 / 100));
}
.cx-theme-high-contrast-dark .btn-secondary.active {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-border-focus);
  color: var(--cx-color-border-focus);
}
.btn-secondary.disabled:hover, .btn-secondary:disabled:hover {
  border-color: var(--cx-color-border-focus);
}
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2em var(--cx-color-border-focus);
  background-color: var(--cx-color-inverse);
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  outline-color: var(--cx-color-visual-focus);
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary-accent);
  color: var(--cx-color-primary-accent);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
}
.cx-theme-high-contrast-dark .btn-secondary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .btn-secondary:not(:disabled):not(.disabled).active {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.cx-theme-high-contrast-dark .btn-secondary {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.btn-link {
  font-size: 1.125rem;
  color: var(--cx-color-text);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
.btn-link:focus {
  border-radius: 4px;
}
.btn-link .focus {
  border-radius: 4px;
}
.cxFeat_a11yImproveContrast .btn-link {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-primary);
}

.btn-tertiary {
  color: var(--cx-color-primary);
  padding: 12px 16px;
  background-color: var(--cx-color-transparent);
  border-style: none;
  font-size: var(--cx-font-size, 1rem);
  width: auto;
}
.cxFeat_a11yImproveContrast .btn-tertiary {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.btn-tertiary:hover {
  background-color: var(--cx-color-transparent);
  color: var(--cx-color-primary-accent);
}
.btn-tertiary.active {
  color: var(--cx-color-primary);
  background-color: var(--cx-color-transparent);
}
.btn-tertiary:focus {
  box-shadow: 0 0 0 0.2em var(--cx-color-visual-focus);
  background-color: var(--cx-color-transparent);
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  outline-color: var(--cx-color-visual-focus);
}
.btn-tertiary.disabled {
  -webkit-filter: brightness(calc(88 / 10));
  filter: brightness(calc(88 / 10));
  color: var(--cx-color-border-focus);
}

.cx-toggle-button {
  position: absolute;
  top: 10px;
  left: 100%;
}
.cx-toggle-button *,
.cx-toggle-button *:before,
.cx-toggle-button *:after {
  box-sizing: border-box;
}
.cx-toggle-button input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch {
  background-color: var(--cx-color-primary);
  border: 1px solid var(--cx-color-primary);
}
.cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch:before {
  content: " ";
  left: 0;
}
.cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch:after {
  content: " ";
  border: 2px solid var(--cx-color-inverse);
  background-color: var(--cx-color-inverse);
  transform: translate3d(22px, 0, 0);
}
.cx-toggle-button input[type=checkbox][disabled] ~ label,
.cx-toggle-button input[type=checkbox][disabled] ~ label .cx-toggle-switch {
  pointer-events: none;
}
.cx-toggle-button input[type=checkbox][disabled]:checked ~ label .cx-toggle-switch {
  background-color: var(--cx-color-light);
  border: 1px solid var(--cx-color-light);
}
.cx-toggle-button input[type=checkbox][disabled]:checked ~ label .cx-toggle-text {
  color: var(--cx-color-light);
}
.cx-toggle-button label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.cx-toggle-button label .cx-toggle-switch {
  position: relative;
  height: 32px;
  flex: 0 0 56px;
  border-radius: 60px;
  border: 1px solid var(--cx-color-secondary);
  background-color: var(--cx-color-inverse);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
}
.cx-toggle-button label .cx-toggle-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
  left: 28px;
  font-size: 12px;
  line-height: 32px;
  width: 28px;
  padding: 0 12px;
}
.cx-toggle-button label .cx-toggle-switch:after {
  content: " ";
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  transform: translate3d(0, 0, 0);
  top: 3px;
  left: 3px;
  border-radius: 30px;
  width: 25px;
  line-height: 20px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  border: 2px solid var(--cx-color-secondary);
  background-color: var(--cx-color-secondary);
  z-index: 1;
}
.cx-toggle-button label .cx-toggle-text {
  flex: 1;
  padding-inline-start: 0.35rem;
  color: var(--cx-color-secondary);
}
.cx-toggle-button input[type=checkbox]:focus ~ label .cx-toggle-switch {
  box-shadow: 0 0 2px 3px var(--cx-color-visual-focus);
}

button:focus,
.btn:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast button:focus,
.cxFeat_a11yImproveContrast .btn:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows button:focus,
.cxFeat_a11yVisibleFocusOverflows .btn:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus button:focus,
  .cxFeat_a11yMobileVisibleFocus .btn:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus button:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus .btn:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus button:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus .btn:focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus button:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus .btn:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.form-control {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.6875rem 0.75rem;
  font-size: 1rem;
  font-weight: "normal";
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #f4f4f4;
  border-color: #6c7079;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.form-control::placeholder {
  color: #7babd2;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #38871f;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(56, 135, 31, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #38871f;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338871f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #38871f;
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #38871f;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338871f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #38871f;
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #38871f;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #38871f;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38871f;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #49b029;
  background-color: #49b029;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38871f;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38871f;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38871f;
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #db0002;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(219, 0, 2, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #db0002;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db0002' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db0002' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #db0002;
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #db0002;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db0002' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db0002' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #db0002;
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #db0002;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #db0002;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #db0002;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff0f11;
  background-color: #ff0f11;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #db0002;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #db0002;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #db0002;
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1f7bc0;
  background-color: #1f7bc0;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #76b7e9;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #a2cef0;
  border-color: #a2cef0;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1f7bc0;
  background-color: #1f7bc0;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(31, 123, 192, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(31, 123, 192, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(31, 123, 192, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.3rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(31, 123, 192, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.6875rem 1.75rem 0.6875rem 0.75rem;
  font-size: 1rem;
  font-weight: "normal";
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #f1f1f1;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #76b7e9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #76b7e9;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: "normal";
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1f7bc0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #a2cef0;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1f7bc0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #a2cef0;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1f7bc0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #a2cef0;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.form-control {
  margin-bottom: 0.25rem;
  color: var(--cx-color-text);
}
.form-control:focus {
  border-color: var(--cx-color-secondary);
  box-shadow: none;
  -webkit-box-shadow: none;
}
.cxFeat_a11yImproveContrast .form-control {
  border: 1px solid var(--cx-color-dark);
}
.cxFeat_a11yImproveContrast .form-control:focus {
  border-color: var(--cx-color-text);
  background-color: var(--cx-color-inverse);
}
.cxFeat_a11yImproveContrast .form-control::placeholder {
  color: var(--cx-color-secondary);
}
.cxFeat_a11yImproveContrast .form-control::-webkit-input-placeholder {
  color: var(--cx-color-secondary);
}
.cxFeat_a11yImproveContrast .form-control:-ms-input-placeholder {
  color: var(--cx-color-secondary);
}
.cxFeat_a11yImproveContrast .form-control::-ms-input-placeholder {
  color: var(--cx-color-secondary);
}

.cx-theme-high-contrast-dark .form-control, .cx-theme-high-contrast-light .form-control {
  border-color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}
.cx-theme-high-contrast-dark .form-control:focus, .cx-theme-high-contrast-light .form-control:focus {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}

cx-form-errors p {
  font-size: 14px;
  margin: 6px 0;
  padding-inline-start: 25px;
  position: relative;
  word-break: break-word;
}
.cxFeat_a11yImproveContrast cx-form-errors p {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-form-errors p::before, cx-form-errors p::after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}
cx-form-errors p::before {
  content: "";
  background-color: var(--cx-color-danger);
  border-radius: 50%;
}
cx-form-errors p::after {
  content: "!";
  color: var(--cx-color-inverse);
  font-weight: var(--cx-font-weight-bold);
  text-align: center;
  line-height: 20px;
}
.cxFeat_a11yFormErrorMuteIcon cx-form-errors p::after {
  content: "!"/"";
}

cx-form-errors p:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

cx-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
cx-file-upload input[type=file] {
  display: none;
}
cx-file-upload button {
  width: 100%;
}

input.ng-invalid.ng-dirty, input.ng-invalid.ng-touched,
input[type=checkbox].ng-invalid.ng-dirty,
input[type=checkbox].ng-invalid.ng-touched,
input[type=radio].ng-invalid.ng-dirty,
input[type=radio].ng-invalid.ng-touched,
textarea.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-touched {
  border-color: var(--cx-color-danger);
}

ng-select.ng-invalid.ng-dirty .ng-select-container, ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: var(--cx-color-danger);
}

input[type=checkbox],
input[type=radio] {
  height: 22px;
  width: 22px;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.42857;
  appearance: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-size: inherit;
  box-sizing: border-box;
  outline: none;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin-top: 0.3rem;
  margin-inline-start: -2.25rem;
}

label {
  display: block;
}
label .label-content {
  display: block;
  margin-bottom: 0.375rem;
}
label .label-content.bold {
  font-weight: var(--cx-font-weight-semi);
}

.notes-content {
  margin-top: 1rem;
}

legend {
  margin-bottom: 0.375rem;
}

.form-check {
  margin-bottom: 18px;
  padding-inline-start: 2.25rem;
}
.form-check label {
  font-weight: var(--cx-font-weight-normal);
}
.form-check .form-check-label {
  display: inline-block;
  position: relative;
  top: 4px;
}
.form-check input + .form-check-label {
  color: var(--cx-color-text);
}
.form-check input + .form-check-label a {
  color: var(--cx-color-text);
  text-decoration: underline;
}
.form-check input + .form-check-label a:hover {
  color: var(--cx-color-primary);
}
@media (max-width: 767.98px) {
  .form-check input + .form-check-label a {
    display: inline;
  }
}
.form-check input + .form-check-label::after {
  content: none;
}
.form-check input:disabled + .form-check-label {
  color: var(--cx-color-light);
}
.form-check input[role=checkbox],
.form-check input[role=radio] {
  display: inline-block;
}
.form-check input[role=checkbox]:focus,
.form-check input[role=radio]:focus {
  box-shadow: 0px 0px 0px 3px var(--cx-color-light);
  border-color: var(--cx-color-secondary);
}
.form-check input[type=checkbox] {
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: var(--cx-color-medium);
}
.cxFeat_a11yImproveContrast .form-check input[type=checkbox] {
  border-color: var(--cx-color-text);
}

.form-check input[type=checkbox]:checked {
  font-style: normal;
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-primary);
}
.form-check input[type=checkbox]:checked::after {
  color: var(--cx-color-inverse);
  position: absolute;
  content: "";
  display: inline-block;
  height: 6px;
  width: 9px;
  top: 5px;
  left: 5px;
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: var(--cx-color-inverse);
  transform: rotate(-45deg);
}
.cx-theme-high-contrast-dark .form-check input[type=checkbox]:checked {
  border-color: var(--cx-color-dark);
  background-color: var(--cx-color-medium);
}

.form-control[type=text]:focus {
  background-color: var(--cx-color-background);
  box-shadow: none;
  -webkit-box-shadow: none;
}
.cxFeat_a11yImproveContrast .form-control[type=text]:focus {
  background-color: var(--cx-color-inverse);
}

.cx-theme-high-contrast-dark .form-control[type=text]:focus, .cx-theme-high-contrast-light .form-control[type=text]:focus {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}

input[type=radio] {
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  border-width: 2px;
  border-color: var(--cx-color-medium);
  position: absolute;
}
.cxFeat_a11yImproveContrast input[type=radio] {
  border-color: var(--cx-color-text);
}

input[type=radio]::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--cx-color-inverse);
  left: 4px;
  top: 4px;
}
input[type=radio]:checked {
  border-color: var(--cx-color-primary) !important;
  background-color: var(--cx-color-inverse);
}
input[type=radio]:checked::after {
  background-color: var(--cx-color-primary);
}

.form-message {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}
.form-message--danger {
  color: var(--cx-color-danger);
}

.form-legend {
  font-style: italic;
  color: var(--cx-color-secondary);
  margin-bottom: 1rem;
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--cx-color-light);
}
.cxFeat_a11yImproveContrast .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.cxFeat_a11yImproveContrast .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  outline: 2px solid var(--cx-color-visual-focus);
  margin-top: 2px;
  margin-bottom: 2px;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
}

.cx-theme-high-contrast-dark .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .cx-theme-high-contrast-light .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.cx-theme-high-contrast-dark .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.cx-theme-high-contrast-light .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--cx-color-background);
}
.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
  border-color: var(--cx-color-text) transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  padding: 0.6875rem 0;
  line-height: 1.6;
  height: unset;
}
.cxFeat_a11yImproveContrast .ng-select .ng-select-container,
.cxFeat_a11yImproveContrast .ng-select.ng-select-single .ng-select-container {
  border: 1px solid var(--cx-color-dark);
}

.cx-theme-high-contrast-dark .ng-select .ng-select-container, .cx-theme-high-contrast-light .ng-select .ng-select-container,
.cx-theme-high-contrast-dark .ng-select.ng-select-single .ng-select-container,
.cx-theme-high-contrast-light .ng-select.ng-select-single .ng-select-container {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
  border-color: var(--cx-color-text);
}
.ng-select .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-inline-start: 10px;
}
.cx-theme-high-contrast-dark .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .cx-theme-high-contrast-light .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.cx-theme-high-contrast-dark .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.cx-theme-high-contrast-light .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}
.cx-theme-high-contrast-dark .ng-select s .ng-arrow-wrapper .ng-arrow, .cx-theme-high-contrast-light .ng-select s .ng-arrow-wrapper .ng-arrow,
.cx-theme-high-contrast-dark .ng-select.ng-select-single s .ng-arrow-wrapper .ng-arrow,
.cx-theme-high-contrast-light .ng-select.ng-select-single s .ng-arrow-wrapper .ng-arrow {
  border-color: var(--cx-color-text) transparent transparent;
}

.ng-select .ng-arrow-wrapper {
  padding-inline-end: 5px;
}
.cx-theme-high-contrast-dark .ng-select, .cx-theme-high-contrast-light .ng-select {
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .cx-theme-high-contrast-light .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}
.cx-theme-high-contrast-dark .ng-select .ng-arrow-wrapper .ng-arrow, .cx-theme-high-contrast-light .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--cx-color-text) transparent transparent;
}

:focus:not(main) {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast :focus:not(main) {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows :focus:not(main) {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus :focus:not(main) {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus :focus:not(main) .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus :focus:not(main)[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus :focus:not(main) > cx-media {
    transition: none;
    padding: 2px;
  }
}

.form-control:focus,
input:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast .form-control:focus,
.cxFeat_a11yImproveContrast input:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows .form-control:focus,
.cxFeat_a11yVisibleFocusOverflows input:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus .form-control:focus,
  .cxFeat_a11yMobileVisibleFocus input:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus .form-control:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus input:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus .form-control:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus input:focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus .form-control:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus input:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

.ng-select-focused {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast .ng-select-focused {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows .ng-select-focused {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus .ng-select-focused {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus .ng-select-focused .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus .ng-select-focused[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus .ng-select-focused > cx-media {
    transition: none;
    padding: 2px;
  }
}

.form-check input[type=radio] {
  appearance: none;
}
.form-check input[type=radio]::after {
  visibility: hidden;
}
.form-check input[type=radio]:checked::after {
  visibility: visible;
}
.form-check input[type=checkbox] {
  appearance: none;
}

.form-check input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-check input[type=radio]::after {
  visibility: hidden;
}
.form-check input[type=radio]:checked::after {
  visibility: visible;
}
.form-check input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.cx-theme-high-contrast-dark .form-check input[type=radio]:checked, .cx-theme-high-contrast-light .form-check input[type=radio]:checked {
  border-color: var(--cx-color-dark) !important;
  background-color: var(--cx-color-light);
}
.cx-theme-high-contrast-dark .form-check input[type=radio]:checked::after, .cx-theme-high-contrast-light .form-check input[type=radio]:checked::after {
  background-color: var(--cx-color-dark);
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--cx-color-secondary);
}
.cx-theme-high-contrast-dark .ng-select .ng-select-container .ng-value-container .ng-placeholder, .cx-theme-high-contrast-light .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}

.form-group input[type=text]::placeholder,
.form-group input[type=tel]::placeholder {
  color: var(--cx-color-secondary);
  opacity: 1;
}
.cxFeat_a11yImproveContrast .form-group input[type=text]::placeholder,
.cxFeat_a11yImproveContrast .form-group input[type=tel]::placeholder {
  color: var(--cx-color-secondary);
}

.form-group input[type=text]:-ms-input-placeholder,
.form-group input[type=tel]:-ms-input-placeholder {
  color: var(--cx-color-secondary);
}
.form-group input[type=text]::-ms-input-placeholder,
.form-group input[type=tel]::-ms-input-placeholder {
  color: var(--cx-color-secondary);
}

.cx-theme-high-contrast-dark form input:-webkit-autofill,
.cx-theme-high-contrast-dark form input:-webkit-autofill:focus, .cx-theme-high-contrast-light form input:-webkit-autofill,
.cx-theme-high-contrast-light form input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: "bold";
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.cx-theme-high-contrast-dark .modal-header, .cx-theme-high-contrast-light .modal-header {
  background-color: var(--cx-color-background);
}
.modal-body {
  padding: 16px 30px 30px;
}
.modal-content {
  border-radius: 0;
  border: none;
}
.cx-theme-high-contrast-dark .modal-content, .cx-theme-high-contrast-light .modal-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .modal-content .close, .cx-theme-high-contrast-light .modal-content .close {
  color: var(--cx-color-text);
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.cxFeat_a11yExpandedFocusIndicator .modal .cx-dialog-header button.close,
.cxFeat_a11yExpandedFocusIndicator .modal button.close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}
.cx-theme-high-contrast-dark .cx-modal-content, .cx-theme-high-contrast-light .cx-modal-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .cx-modal-content .close, .cx-theme-high-contrast-light .cx-modal-content .close {
  color: var(--cx-color-text);
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.alert {
  position: relative;
  padding: 20px 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: "bold";
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 20px 1.25rem;
  color: inherit;
}

.alert-background {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-background hr {
  border-top-color: #efefef;
}
.alert-background .alert-link {
  color: #666666;
}

.alert-text {
  color: #0a151e;
  background-color: #d0d4d8;
  border-color: #bdc3c8;
}
.alert-text hr {
  border-top-color: #afb6bc;
}
.alert-text .alert-link {
  color: black;
}

.alert-inverse {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-inverse hr {
  border-top-color: #f2f2f2;
}
.alert-inverse .alert-link {
  color: #6c6c6c;
}

.alert-transparent {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.72);
}
.alert-transparent hr {
  border-top-color: rgba(242, 242, 242, 0.72);
}
.alert-transparent .alert-link {
  color: rgba(0, 0, 0, 0.48);
}

.alert-primary {
  color: #104064;
  background-color: #d2e5f2;
  border-color: #c0daed;
}
.alert-primary hr {
  border-top-color: #accee7;
}
.alert-primary .alert-link {
  color: #092438;
}

.alert-secondary {
  color: #383a3f;
  background-color: #e2e2e4;
  border-color: #d6d7d9;
}
.alert-secondary hr {
  border-top-color: #c9cacd;
}
.alert-secondary .alert-link {
  color: #202124;
}

.alert-success {
  color: #1d4610;
  background-color: #d7e7d2;
  border-color: #c7ddc0;
}
.alert-success hr {
  border-top-color: #b8d4af;
}
.alert-success .alert-link {
  color: #0c1c07;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #720001;
  background-color: #f8cccc;
  border-color: #f5b8b8;
}
.alert-danger hr {
  border-top-color: #f2a2a2;
}
.alert-danger .alert-link {
  color: #3f0001;
}

.alert-light {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-light hr {
  border-top-color: #eeeeee;
}
.alert-light .alert-link {
  color: #646464;
}

.alert-dark {
  color: #383a3f;
  background-color: #e2e2e4;
  border-color: #d6d7d9;
}
.alert-dark hr {
  border-top-color: #c9cacd;
}
.alert-dark .alert-link {
  color: #202124;
}

.alert-primary-accent {
  color: #033153;
  background-color: #cddfec;
  border-color: #b9d2e4;
}
.alert-primary-accent hr {
  border-top-color: #a7c6dd;
}
.alert-primary-accent .alert-link {
  color: #011422;
}

.alert-success-accent {
  color: #7d8377;
  background-color: #fcfefa;
  border-color: #fbfef7;
}
.alert-success-accent hr {
  border-top-color: #f0fbe0;
}
.alert-success-accent .alert-link {
  color: #64685f;
}

.alert-danger-accent {
  color: #857d7d;
  background-color: #fffcfc;
  border-color: #fffbfb;
}
.alert-danger-accent hr {
  border-top-color: #ffe2e2;
}
.alert-danger-accent .alert-link {
  color: #6b6464;
}

.alert-warning-accent {
  color: #857f74;
  background-color: #fffdf9;
  border-color: #fffcf6;
}
.alert-warning-accent hr {
  border-top-color: #fff4dd;
}
.alert-warning-accent .alert-link {
  color: #6a655c;
}

.alert-info-accent {
  color: #737c84;
  background-color: #f8fcff;
  border-color: #f6fbff;
}
.alert-info-accent hr {
  border-top-color: #ddf0ff;
}
.alert-info-accent .alert-link {
  color: #5b6269;
}

.alert-medium {
  color: #6e6f72;
  background-color: #f6f7f8;
  border-color: #f3f4f5;
}
.alert-medium hr {
  border-top-color: #e5e7e9;
}
.alert-medium .alert-link {
  color: #555658;
}

.alert-background-dark {
  color: #11141d;
  background-color: #d3d4d7;
  border-color: #c1c3c7;
}
.alert-background-dark hr {
  border-top-color: #b4b6bb;
}
.alert-background-dark .alert-link {
  color: black;
}

.alert-visual-focus {
  color: #395280;
  background-color: #e2ebfd;
  border-color: #d6e4fd;
}
.alert-visual-focus hr {
  border-top-color: #bed4fc;
}
.alert-visual-focus .alert-link {
  color: #293b5d;
}

.alert-background-focus {
  color: rgba(4, 9, 13, 0.532);
  background-color: rgba(253, 254, 255, 0.82);
  border-color: rgba(251, 253, 255, 0.748);
}
.alert-background-focus hr {
  border-top-color: rgba(226, 240, 255, 0.748);
}
.alert-background-focus .alert-link {
  color: rgba(0, 0, 0, 0.532);
}

.alert-border-focus {
  color: rgba(53, 65, 74, 0.792);
  background-color: rgba(246, 250, 253, 0.92);
  border-color: rgba(242, 248, 252, 0.888);
}
.alert-border-focus hr {
  border-top-color: rgba(221, 237, 247, 0.888);
}
.alert-border-focus .alert-link {
  color: rgba(32, 39, 44, 0.792);
}

.alert {
  text-align: var(--cx-text-align, center);
  color: var(--cx-color, var(--cx-color-text));
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-top: 0;
  padding-inline-end: 4rem;
  padding-bottom: 0;
  padding-inline-start: 20px;
  line-height: var(--cx-line-height, 1.5);
  min-height: 70px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .alert {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-semi);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
.alert-icon {
  height: var(--cx-height, 25px);
  margin-inline-end: var(--cx-margin-right, 0.5rem);
  position: var(--cx-position, relative);
  display: var(--cx-display, inline-block);
  vertical-align: var(--cx-vertical-align, middle);
}
.alert-icon::before {
  position: var(--cx-position, relative);
  font-size: 18px;
  font-weight: "bold";
  text-align: var(--cx-text-align, center);
  display: var(--cx-display, inline-block);
  width: var(--cx-width, 26px);
  height: var(--cx-height, 26px);
}
.alert-success {
  background-color: #d7e7d2;
  border: var(--cx-border, none);
}
.alert-success .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-success));
}
.alert-danger {
  background-color: #fad9d9;
  border: var(--cx-border, none);
}
.alert-danger .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-danger));
}
.alert-info {
  background-color: #d1ecf1;
  border: var(--cx-border, none);
}
.alert-info .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-info));
}
.alert-warning {
  background-color: #fff1c8;
  border: var(--cx-border, none);
}
.alert-warning .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-warning));
}
.alert .close {
  font-weight: var(--cx-font-weight, var(--cx-font-weight-normal));
  position: var(--cx-position, absolute);
  top: var(--cx-top, 32%);
  right: auto;
}
.alert .close::before {
  content: "";
  margin: 0 45vw;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.cx-message {
  color: var(--cx-color-text);
  margin-bottom: 30px;
  position: relative;
}
.cx-message-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: var(--cx-page-width-max);
}
.cx-message-header {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: var(--cx-font-weight-semi);
  justify-content: flex-start;
  line-height: var(--cx-line-height, 1.5);
  min-height: 45px;
  padding-bottom: 0;
  padding-inline-end: 4rem;
  padding-inline-start: 20px;
  padding-top: 0;
  position: relative;
  text-align: var(--cx-text-align, center);
}
.cxFeat_a11yImproveContrast .cx-message-header {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-message-text {
  align-items: center;
  display: flex;
}
.cx-message-text button {
  margin-inline-start: 10px;
}
.cx-message-text .cx-message-accordion-button .cx-message-accordion-icon {
  font-size: 17px;
  margin-inline-start: 5px;
}
.cx-message-body {
  font-size: var(--cx-font-size, 0.8125rem);
  font-weight: var(--cx-font-weight-normal);
  padding: 0 12px;
}
.cxFeat_a11yImproveContrast .cx-message-body {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-message-icon {
  display: flex;
  font-size: var(--cx-font-size, 1.25rem);
  height: var(--cx-height, 25px);
  margin-inline-end: var(--cx-margin-right, 0.5rem);
  position: var(--cx-position, relative);
  vertical-align: var(--cx-vertical-align, middle);
}
.cx-message-icon::before {
  display: var(--cx-display, inline-block);
  font-size: var(--cx-font-size, 1.25rem);
  font-weight: "bold";
  height: var(--cx-height, 26px);
  position: var(--cx-position, relative);
  text-align: var(--cx-text-align, center);
  width: var(--cx-width, 26px);
}
.cx-message-success {
  background-color: #d7e7d2;
  border: 1px solid var(--cx-color-success);
}
.cx-message-success .cx-message-icon cx-icon {
  color: var(--cx-color-success);
}
.cx-message-danger {
  background-color: #fad9d9;
  border: 1px solid var(--cx-color-danger);
}
.cx-message-danger .cx-message-icon cx-icon {
  color: var(--cx-color-danger);
}
.cx-message-info {
  background-color: #d1ecf1;
  border: 1px solid var(--cx-color-info);
}
.cx-message-info .cx-message-icon cx-icon {
  color: var(--cx-color-info);
}
.cx-message-warning {
  background-color: #fff1c8;
  border: 1px solid var(--cx-color-warning);
}
.cx-message-warning .cx-message-icon cx-icon {
  color: var(--cx-color-warning);
}
.cx-message .close {
  font-weight: var(--cx-font-weight-normal);
  position: var(--cx-position, absolute);
  right: 20px;
  top: 11px;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: "normal";
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^=left] .arrow:before {
  border-left-color: #14293a;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background-color: #14293a;
  padding: 9px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.table {
  width: 100%;
}
.table thead {
  border-bottom: 1px solid var(--cx-color-medium);
}
.table thead th {
  border-bottom: 0 none;
  border-top: none;
  font-size: 0.875rem;
  font-weight: "semi";
  text-transform: uppercase;
  color: #6c7079;
}
.cxFeat_a11yImproveContrast .table thead th {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.table tr {
  width: 100%;
  border-bottom: 1px solid var(--cx-color-medium);
}
@media (max-width: 767.98px) {
  .table tr:first-child {
    padding: 1.25rem 0 0 0;
  }
}
.table td {
  vertical-align: middle;
}
.table-hover tbody tr {
  min-height: 74px;
}
.table-hover tbody tr:hover {
  background-color: #f4f4f4;
}
.table--mobile {
  font-weight: "bold";
  display: none;
}
@media (max-width: 767.98px) {
  .table--mobile {
    display: inline-flex;
  }
}
.table-close {
  position: relative;
  right: 18px;
  width: 18px;
  height: 18px;
}
.table-close:hover {
  opacity: 1;
}
.table-close:before, .table-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 18px;
  width: 2px;
  background-color: #6c7079;
  top: 30%;
}
.table-close:before {
  transform: rotate(45deg);
}
.table-close:after {
  transform: rotate(-45deg);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card {
  border-radius: 0;
}

.card-body {
  padding: 20px;
}

.card-body-title {
  padding-bottom: 10px;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}

.card-header {
  background-color: var(--cx-color-secondary);
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-inverse);
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0;
}
.card-header:first-child {
  border-radius: 0;
}

.card-actions-container {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 0 0 0;
}

.card-label-bold {
  font-weight: var(--cx-font-weight-bold);
}

.card-link {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.accordion .card {
  margin-bottom: 20px;
  border: none;
}
.accordion .card-header {
  background-color: #f4f4f4;
  padding: 25px 20px;
  border: none;
}
.accordion .card-header h5 {
  font-size: 1.375rem;
  font-weight: 600;
  color: #14293a;
}
.cxFeat_a11yImproveContrast .accordion .card-header h5 {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.accordion .card-header h5 .btn {
  text-align: start;
  text-decoration: none !important;
  width: 100%;
  font-weight: 600;
}
.accordion .card-header h5 .btn:hover {
  text-decoration: none;
}
.accordion .card-header h5 .btn:after {
  float: right;
  font-size: 33px;
  bottom: 12px;
  position: relative;
}
.accordion .card-header h5 .btn[aria-expanded=false]:after {
  content: "+";
}
.accordion .card-header h5 .btn[aria-expanded=true] {
  color: #1f7bc0;
}
.accordion .card-header h5 .btn[aria-expanded=true]:after {
  content: "–";
}
.accordion .card-body {
  padding: 30px 0 20px 0;
}

.cx-accordion {
  margin: 5px auto;
  line-height: 1.6;
  list-style-type: none;
  padding: 0;
}
.cx-accordion .cx-accordion-item {
  border: 1px solid var(--cx-color-light);
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cx-accordion .cx-accordion-item .cx-accordion-tab {
  background-color: var(--cx-color-inverse);
}
.cx-accordion .cx-accordion-item .cx-accordion-tab:hover {
  cursor: pointer;
}
.cx-accordion .cx-accordion-item .cx-accordion-tab .cx-accordion-title {
  padding-top: 15px;
  padding-inline-end: 15px;
  padding-bottom: 15px;
  padding-inline-start: 50px;
  display: block;
  position: relative;
  font-weight: 600;
}
.cx-accordion .cx-accordion-item .cx-accordion-tabpanel {
  transition: height 0.3s ease-out;
  height: 0;
  overflow: hidden;
  background-color: var(--cx-color-inverse);
}
.cx-accordion .cx-accordion-item .cx-accordion-tabpanel .cx-accordion-content {
  padding-top: 0;
  padding-inline-end: 40px;
  padding-bottom: 10px;
  padding-inline-start: 40px;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

ul[role=tablist] .nav-link {
  border: none;
}
ul[role=tablist] .nav-link.active {
  background-color: var(--cx-color-inverse);
  color: var(--cx-color-primary);
  border-width: 0 0 5px 0;
  border-style: solid;
}
ul[role=tablist] .nav-link.active div {
  color: var(--cx-color-primary);
  font-weight: 600;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.carousel-indicators {
  bottom: 0;
  margin-bottom: 0;
}
.carousel-indicators li {
  margin-inline-start: 7px;
}
@media (min-width: 1200px) {
  .carousel-indicators li {
    margin-inline-start: 25px;
  }
}
.carousel-indicators > .active {
  background-color: #1f7bc0;
}
.carousel-indicators > li {
  background-color: #f1f1f1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
@media (min-width: 1200px) {
  .carousel-indicators > li {
    width: 18px;
    height: 18px;
  }
}

.carousel-inner {
  margin-inline-start: auto;
  margin-inline-end: auto;
}
@media (min-width: 992px) {
  .carousel-inner {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }
}

a {
  color: var(--cx-color-primary);
}
a:hover {
  color: var(--cx-color-primary);
}
a.disabled {
  pointer-events: var(--cx-pointer-event, none !important);
  cursor: var(--cx-cursor, not-allowed !important);
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  a {
    min-height: 48px;
    min-width: 48px;
    display: block;
  }
}

.cx-action-link {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  text-transform: var(--cx-button-text-transform);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  color: var(--cx-color-primary);
}
.cx-action-link:hover {
  color: var(--cx-color-primary-accent);
  text-decoration: none;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: "normal";
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1f7bc0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.dropdown-menu {
  border-radius: 0;
  margin-top: 11px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: transparent;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.cx-app__breadcrumb {
  background-color: #f4f4f4;
  text-align: center;
  padding-top: 25px;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.cx-page {
  padding-bottom: 120px;
}

.cx-page-header {
  background-color: var(--cx-color-background);
  text-align: center;
  padding: 25px 0;
}

.cx-page-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-semi);
  margin: 0 auto;
}

.cx-page-section {
  padding-top: 60px;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.cx-theme-high-contrast-dark {
  --cx-color-medium: #000000;
  --cx-color-text: #ffffff;
  --cx-color-primary: #6bd3ff;
  --cx-color-visual-focus: #b995e0;
  --cx-color-secondary: #b0bcc5;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #6bd3ff;
  --cx-color-background-dark: #000000;
  --cx-color-dark: #ffffff;
  --cx-color-light: #000000;
  --cx-color-background: #000000;
  --cx-color-info: #ffffff;
  --cx-color-warning: #ffab1d;
  --cx-color-success: #99cc99;
  --cx-color-danger: #ff5e5e;
  --cx-color-danger-accent: #000000;
  --cx-color-success-accent: #000000;
  --cx-color-info-accent: #000000;
  --cx-color-warning-accent: #000000;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-light {
  --cx-color-medium: #ffffff;
  --cx-color-text: #000000;
  --cx-color-primary: #004ccb;
  --cx-color-visual-focus: #6c32a9;
  --cx-color-secondary: #4a5964;
  --cx-color-inverse: #ffffff;
  --cx-color-primary-accent: #004ccb;
  --cx-color-background-dark: #ffffff;
  --cx-color-dark: #000000;
  --cx-color-light: #ffffff;
  --cx-color-background: #ffffff;
  --cx-color-info: #000000;
  --cx-color-warning: #5c5c00;
  --cx-color-success: #006326;
  --cx-color-danger: #ab0000;
  --cx-color-danger-accent: #ffffff;
  --cx-color-success-accent: #ffffff;
  --cx-color-info-accent: #ffffff;
  --cx-color-warning-accent: #ffffff;
  --cx-color-transparent: transparent;
}

.cx-theme-high-contrast-dark,
.cx-theme-high-contrast-light {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
}

.cx-section {
  margin-bottom: var(--cx-margin-bottom, 50px);
}
.cx-section:last-child {
  margin-bottom: var(--cx-margin-bottom, 0px);
}

.cx-section-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight, var(--cx-font-weight-semi));
  margin-bottom: var(--cx-margin-bottom, 25px);
  text-transform: var(--cx-text-transform, none);
}

.cx-section-title-alt {
  text-transform: var(--cx-text-transform, none);
}

.cx-notice {
  padding: 32px 0;
  text-align: center;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  color: var(--cx-color-dark);
  background-color: var(--cx-color-inverse);
  margin-bottom: -1.5rem;
}
.cxFeat_a11yImproveContrast .cx-notice {
  color: var(--cx-color-text);
}

.cx-theme-high-contrast-dark .cx-notice, .cx-theme-high-contrast-light .cx-notice {
  background-color: var(--cx-color-background);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --cx-spinner-size: 40px;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger, .cx-hamburger {
  padding: 10px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover, .cx-hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover, .is-active.cx-hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner, .is-active.cx-hamburger .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.is-active.cx-hamburger .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after,
.is-active.cx-hamburger .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 26px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 26px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner, .cx-hamburger .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before, .cx-hamburger .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after, .cx-hamburger .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner, .is-active.cx-hamburger .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before, .is-active.cx-hamburger .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after, .is-active.cx-hamburger .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cx-dialog-popover-center, .cx-dialog-popover-center-backdrop, .cx-dialog-popover, .cx-sidebar-end, .cx-sidebar-start {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: 0;
}

.cx-sidebar-start {
  display: flex;
  justify-content: flex-start;
}

.cx-sidebar-end {
  display: flex;
  justify-content: flex-end;
}

.cx-dialog-popover-center, .cx-dialog-popover-center-backdrop {
  display: grid;
  place-items: center;
}

.cx-dialog-popover-center-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.cx-agnostic-table table, cx-quote-list table {
  margin: 1rem 0;
  border-collapse: collapse;
  width: 100%;
}
.cx-agnostic-table th, cx-quote-list th,
.cx-agnostic-table td,
cx-quote-list td {
  padding: 1.25rem 0.1rem 1.25rem 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.cx-agnostic-table th:nth-of-type(1), cx-quote-list th:nth-of-type(1),
.cx-agnostic-table td:nth-of-type(1),
cx-quote-list td:nth-of-type(1) {
  text-align: start;
}
.cx-agnostic-table th:nth-last-of-type(1), cx-quote-list th:nth-last-of-type(1),
.cx-agnostic-table td:nth-last-of-type(1),
cx-quote-list td:nth-last-of-type(1) {
  text-align: end;
}
.cx-agnostic-table tr, cx-quote-list tr {
  border-top: 1px solid var(--cx-color-medium);
  padding: 1.25rem 0;
}
.cx-agnostic-table th, cx-quote-list th {
  vertical-align: bottom;
  text-transform: capitalize;
  text-align: center;
  color: var(--cx-color-secondary);
  font-weight: var(--cx-font-weight-bold);
  font-size: var(--cx-font-size, 0.875rem);
  white-space: nowrap;
}
.cxFeat_a11yImproveContrast .cx-agnostic-table th, .cxFeat_a11yImproveContrast cx-quote-list th {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-agnostic-table th:nth-last-of-type(1), cx-quote-list th:nth-last-of-type(1) {
  padding-inline-end: 16px;
}
.cx-agnostic-table .cx-mobile-header, cx-quote-list .cx-mobile-header {
  display: none;
}
.cx-agnostic-table .cx-mobile-only, cx-quote-list .cx-mobile-only {
  display: none;
}
@media (max-width: 991.98px) {
  .cx-agnostic-table table, cx-quote-list table,
  .cx-agnostic-table tr,
  cx-quote-list tr,
  .cx-agnostic-table td,
  cx-quote-list td {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  .cx-agnostic-table td, cx-quote-list td {
    align-items: center;
    padding: 0.313rem;
  }
  .cx-agnostic-table thead, cx-quote-list thead {
    display: none;
  }
  .cx-agnostic-table .cx-mobile-header, cx-quote-list .cx-mobile-header {
    display: block;
    white-space: nowrap;
    text-align: start;
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    overflow-wrap: break-word;
    padding-inline-end: 1rem;
    min-width: 5rem;
  }
  .cx-agnostic-table .cx-mobile-only, cx-quote-list .cx-mobile-only {
    display: flex;
  }
}
.cx-theme-high-contrast-dark .cx-agnostic-table tr, .cx-theme-high-contrast-dark cx-quote-list tr, .cx-theme-high-contrast-light .cx-agnostic-table tr, .cx-theme-high-contrast-light cx-quote-list tr {
  border-color: var(--cx-color-dark);
}

cx-quote-comments .cx-toggle {
  cursor: pointer;
  user-select: none;
  margin-bottom: 1rem;
}
cx-quote-comments .cx-toggle cx-icon {
  padding-inline-end: 5px;
}
cx-quote-comments .cx-toggle cx-icon,
cx-quote-comments .cx-toggle .cx-text {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-semi);
}
cx-quote-comments cx-messaging .container {
  min-height: 0px;
}
cx-quote-comments cx-messaging .cx-message-footer {
  padding-inline-start: 1rem;
}
cx-quote-comments cx-messaging .cx-message-input {
  padding-block-start: 0.5rem;
}
cx-quote-comments .cx-ghost-comment {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-block-end: 1rem;
}
cx-quote-comments .cx-ghost-comment .cx-ghost-input-title,
cx-quote-comments .cx-ghost-comment .cx-ghost-input {
  width: 100%;
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-comments .cx-ghost-comment .cx-ghost-input-title,
  cx-quote-comments .cx-ghost-comment .cx-ghost-input {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-comments .cx-ghost-comment .cx-ghost-input-title,
  cx-quote-comments .cx-ghost-comment .cx-ghost-input {
    height: 25px;
  }
}
cx-quote-comments .cx-ghost-comment .cx-ghost-input {
  height: 140px;
}

cx-quote-confirm-dialog .cx-modal-container {
  justify-content: center;
}
@media (max-width: 767.98px) {
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content {
    min-width: 100%;
    min-height: 100%;
  }
}
@media (min-width: 768px) {
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content {
    width: 60%;
    padding: 0.3rem;
  }
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-header .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-header button.close {
  padding-inline-end: 0px;
}
.cx-theme-high-contrast-dark cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-header, .cx-theme-high-contrast-light cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-header {
  background-color: var(--cx-color-background);
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body {
  padding: 1rem;
}
@media (max-width: 767.98px) {
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body {
    padding: 0;
    background-color: var(--cx-color-inverse);
  }
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-content-container {
  border-bottom: 1px solid var(--cx-color-light);
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-content-container > div {
  margin-block-end: 1rem;
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-content-container .cx-heading {
  font-weight: var(--cx-font-weight-bold);
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-notes-container {
  margin-block-end: 2rem;
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-notes-container p {
  font-weight: var(--cx-font-weight-semi);
  margin-block-end: 0;
  margin-block-start: 1.5rem;
}
.cx-theme-high-contrast-dark cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body, .cx-theme-high-contrast-light cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body {
  background-color: var(--cx-color-background);
}
@media (max-width: 767.98px) {
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-header,
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-dialog-body {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
  }
}
cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-block-start: 0.5rem;
}
@media (max-width: 767.98px) {
  cx-quote-confirm-dialog .cx-modal-container .cx-modal-content .cx-buttons-container {
    flex-direction: column;
  }
}

cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-border, cx-quote-header-buyer-edit .cx-card-border {
  border: 1px solid var(--cx-color-medium);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-container, cx-quote-header-buyer-edit .cx-card-container {
  display: flex;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-label-container, cx-quote-header-buyer-edit .cx-card-label-container {
  flex-grow: 2;
  white-space: pre-line;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-text-green, cx-quote-header-buyer-edit .cx-text-green {
  color: var(--cx-color-success);
  font-weight: var(--cx-font-weight-bold);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-text-gray, cx-quote-header-buyer-edit .cx-text-gray {
  color: var(--cx-color-secondary);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-title, cx-quote-header-buyer-edit .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-normal);
  margin-bottom: 0.5rem;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph, cx-quote-header-buyer-edit .cx-card-paragraph {
  padding-inline-start: 0.7em;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph .cx-card-paragraph-title, cx-quote-header-buyer-edit .cx-card-paragraph .cx-card-paragraph-title {
  padding-top: 0.7em;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-fit-to-container, cx-quote-header-buyer-edit .cx-card-fit-to-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body, cx-quote-header-buyer-edit .cx-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-delete, cx-quote-header-buyer-edit .cx-card-delete {
  background-color: var(--cx-color-background);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body-delete, cx-quote-header-buyer-edit .cx-card-body-delete {
  padding: 1rem 0 0 0;
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body-delete .btn-secondary, cx-quote-header-buyer-edit .cx-card-body-delete .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-delete-msg, cx-quote-header-buyer-edit .cx-card-delete-msg {
  color: var(--cx-color-danger);
  padding: 0 0 1.25rem 0;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-actions, cx-quote-header-buyer-edit .cx-card-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 0 0 0;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-link, cx-quote-header-buyer-edit .cx-card-link {
  margin-inline-start: 1rem;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card button.link, cx-quote-header-buyer-edit button.link {
  margin-inline-start: 1rem;
}
.cx-theme-high-contrast-dark cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-dark .cx-summary-card .cx-card, .cx-theme-high-contrast-dark cx-quote-header-buyer-edit .cx-card, .cx-theme-high-contrast-light cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-light .cx-summary-card .cx-card, .cx-theme-high-contrast-light cx-quote-header-buyer-edit .cx-card {
  padding: 2px;
}
.cx-theme-high-contrast-dark cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .card-body, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-dark .cx-summary-card .card-body, .cx-theme-high-contrast-dark cx-quote-header-buyer-edit .card-body, .cx-theme-high-contrast-light cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .card-body, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-light .cx-summary-card .card-body, .cx-theme-high-contrast-light cx-quote-header-buyer-edit .card-body {
  background-color: var(--cx-color-background);
}

cx-quote-header-buyer-edit .cx-card-paragraph textarea,
cx-quote-header-buyer-edit .cx-card-paragraph input {
  width: 100%;
  border: 1px solid var(--cx-color-medium);
}
cx-quote-header-buyer-edit .cx-card-paragraph .cx-info-text {
  font-size: var(--cx-font-size, 0.75rem);
  font-style: italic;
  text-align: end;
  margin: 0;
}
cx-quote-header-buyer-edit .cx-card-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-block-start: 1rem;
  gap: 0.5rem;
}
@media (max-width: 767.98px) {
  cx-quote-header-buyer-edit .cx-card-button-container {
    flex-wrap: wrap;
  }
}
@media (min-width: 576px) {
  cx-quote-header-buyer-edit .cx-card-button-container .btn {
    width: fit-content;
  }
}
@media (max-width: 767.98px) {
  cx-quote-header-buyer-edit .cx-card-button-container .btn {
    width: 100%;
  }
}

cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-border, cx-quote-header-buyer-edit .cx-card-border {
  border: 1px solid var(--cx-color-medium);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-container, cx-quote-header-buyer-edit .cx-card-container {
  display: flex;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-label-container, cx-quote-header-buyer-edit .cx-card-label-container {
  flex-grow: 2;
  white-space: pre-line;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-text-green, cx-quote-header-buyer-edit .cx-text-green {
  color: var(--cx-color-success);
  font-weight: var(--cx-font-weight-bold);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-text-gray, cx-quote-header-buyer-edit .cx-text-gray {
  color: var(--cx-color-secondary);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-title, cx-quote-header-buyer-edit .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-normal);
  margin-bottom: 0.5rem;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph, cx-quote-header-buyer-edit .cx-card-paragraph {
  padding-inline-start: 0.7em;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph .cx-card-paragraph-title, cx-quote-header-buyer-edit .cx-card-paragraph .cx-card-paragraph-title {
  padding-top: 0.7em;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-fit-to-container, cx-quote-header-buyer-edit .cx-card-fit-to-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body, cx-quote-header-buyer-edit .cx-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-delete, cx-quote-header-buyer-edit .cx-card-delete {
  background-color: var(--cx-color-background);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body-delete, cx-quote-header-buyer-edit .cx-card-body-delete {
  padding: 1rem 0 0 0;
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-body-delete .btn-secondary, cx-quote-header-buyer-edit .cx-card-body-delete .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-delete-msg, cx-quote-header-buyer-edit .cx-card-delete-msg {
  color: var(--cx-color-danger);
  padding: 0 0 1.25rem 0;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-actions, cx-quote-header-buyer-edit .cx-card-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 0 0 0;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-link, cx-quote-header-buyer-edit .cx-card-link {
  margin-inline-start: 1rem;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card button.link, cx-quote-header-buyer-edit button.link {
  margin-inline-start: 1rem;
}
.cx-theme-high-contrast-dark cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-dark .cx-summary-card .cx-card, .cx-theme-high-contrast-dark cx-quote-header-buyer-edit .cx-card, .cx-theme-high-contrast-light cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-light .cx-summary-card .cx-card, .cx-theme-high-contrast-light cx-quote-header-buyer-edit .cx-card {
  padding: 2px;
}
.cx-theme-high-contrast-dark cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .card-body, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-dark .cx-summary-card .card-body, .cx-theme-high-contrast-dark cx-quote-header-buyer-edit .card-body, .cx-theme-high-contrast-light cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .card-body, cx-quote-header-overview:not(:empty) .cx-container .cx-theme-high-contrast-light .cx-summary-card .card-body, .cx-theme-high-contrast-light cx-quote-header-buyer-edit .card-body {
  background-color: var(--cx-color-background);
}

cx-quote-header-overview:not(:empty) .cx-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
  gap: 1rem;
}
@media (max-width: 991.98px) {
  cx-quote-header-overview:not(:empty) .cx-container {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-container {
    flex-direction: column;
  }
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card {
  position: relative;
  flex: 1;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border: 1px solid var(--cx-color-medium);
  border-radius: var(--cx-border-radius, 1rem);
}
@media (max-width: 991.98px) {
  cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card {
    flex: 1;
  }
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-edit-btn {
  position: absolute;
  top: 18px;
  right: 10px;
  min-height: 5px;
  min-width: 5px;
  padding: 2px;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-title {
  font-weight: var(--cx-font-weight-bold);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph-text {
  font-weight: var(--cx-font-weight-bold);
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-description {
  max-width: 100%;
  word-break: break-word;
}
cx-quote-header-overview:not(:empty) .cx-container .cx-summary-card .cx-card-paragraph {
  padding-inline-start: 0;
}
cx-quote-header-overview:not(:empty) .cx-ghost-heading {
  padding-block-start: 4rem;
}
cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
  gap: 1rem;
  padding-block-end: 1rem;
}
cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-header,
cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-status {
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-header,
  cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-status {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-header,
  cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-status {
    height: 25px;
  }
}
cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-header {
  width: 50%;
}
cx-quote-header-overview:not(:empty) .cx-ghost-heading .cx-ghost-column .cx-ghost-status {
  width: 80%;
}
cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding-block-end: 1rem;
}
@media (min-width: 768px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row {
    padding-block-end: 4rem;
  }
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row {
    flex-direction: column;
  }
}
cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row .cx-ghost-card {
  min-width: 180px;
  min-height: 150px;
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row .cx-ghost-card {
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-quote-header-overview:not(:empty) .cx-ghost-cards .cx-ghost-row .cx-ghost-card {
    width: 30%;
  }
}

html[dir=rtl] cx-quote-header-overview .cx-container .cx-summary-card .cx-edit-btn {
  margin-inline-end: 1rem;
  margin-inline-start: 0;
}

cx-quote-items .cx-toggle {
  cursor: pointer;
  user-select: none;
  margin-bottom: 1rem;
}
cx-quote-items .cx-toggle cx-icon {
  padding-inline-end: 5px;
}
cx-quote-items .cx-toggle cx-icon,
cx-quote-items .cx-toggle .cx-text {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-semi);
}
cx-quote-items .cx-ghost-table-header,
cx-quote-items .cx-ghost-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
cx-quote-items .cx-ghost-table-header .cx-ghost-title {
  width: 100%;
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table-header .cx-ghost-title {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-items .cx-ghost-table-header .cx-ghost-title {
    height: 25px;
  }
}
@media (min-width: 768px) {
  cx-quote-items .cx-ghost-table-header .cx-ghost-title {
    height: 45px;
  }
}
cx-quote-items .cx-ghost-table {
  padding-block-end: 1rem;
}
cx-quote-items .cx-ghost-table .cx-ghost-row {
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--cx-color-light);
  padding-block-start: 1rem;
  padding-block-end: 1rem;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row {
    flex-direction: column;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-image,
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-info,
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-qty,
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-total,
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-action {
  background-color: var(--cx-color-ghost);
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-image-container {
  width: 20%;
  padding: 1rem;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-image-container {
    padding: 1rem 0;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-image-container .cx-ghost-image {
  width: 70%;
  min-width: 80px;
  min-height: 80px;
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
}
@media (min-width: 768px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container {
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    width: 100%;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 25%;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-info-container {
    width: 80%;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-info-container .cx-ghost-info {
  height: 15px;
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-qty {
  width: 10%;
  height: 25px;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-qty {
    width: 50%;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-total {
  width: 25%;
  height: 35px;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-total {
    width: 60%;
  }
}
cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-action {
  width: 10%;
  height: 35px;
}
@media (max-width: 767.98px) {
  cx-quote-items .cx-ghost-table .cx-ghost-row .cx-ghost-container .cx-ghost-action {
    width: 50%;
  }
}

cx-quote-links:not(:empty) section {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  cx-quote-links:not(:empty) section {
    padding-block-end: 1.25rem;
  }
}
cx-quote-links:not(:empty) section ul {
  display: flex;
  list-style: none;
  margin: 0;
}
cx-quote-links:not(:empty) section ul li {
  padding: 0 1em;
  text-align: center;
  border-style: solid;
  border-color: var(--cx-color-light);
  border-width: 0 1px 0 0;
}

html[dir=ltr] cx-quote-links ul li:last-child {
  border: none;
  padding-inline-end: 0;
}

html[dir=rtl] cx-quote-links ul li:first-child {
  border-width: 0;
}

cx-quote-list table tr:hover {
  cursor: pointer;
  background-color: var(--cx-color-background);
}
cx-quote-list table tr th {
  text-align: start;
  text-transform: capitalize;
}
cx-quote-list table tr td {
  text-align: start;
}
cx-quote-list table tr td:nth-last-of-type(1) {
  text-align: center;
}
@media (max-width: 991.98px) {
  cx-quote-list table tbody {
    width: 100%;
  }
  cx-quote-list table tbody tr {
    position: relative;
  }
  cx-quote-list table tbody tr td {
    align-items: start;
  }
  cx-quote-list table tbody tr td .cx-mobile-header {
    min-width: 30%;
  }
}
cx-quote-list table tbody .cx-name,
cx-quote-list table tbody .cx-updated-date {
  width: 25%;
}
cx-quote-list table tbody .cx-status,
cx-quote-list table tbody .cx-code {
  width: 20%;
}
cx-quote-list table tbody .cx-name {
  padding-inline-end: 1.5rem;
}
@media (min-width: 992px) {
  cx-quote-list table tbody .cx-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 1px;
  }
}
@media (max-width: 991.98px) {
  cx-quote-list table tbody .cx-name {
    display: flex;
    flex-direction: row;
  }
}
cx-quote-list table tbody .cx-name span {
  font-weight: var(--cx-font-weight-semi);
  color: var(--cx-color-primary);
}
@media (max-width: 991.98px) {
  cx-quote-list table tbody .cx-name span {
    width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 350px;
    padding-inline-start: 5px;
  }
}
cx-quote-list table tbody .cx-status span {
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 991.98px) {
  cx-quote-list table tbody .cx-nav-caret {
    position: absolute;
    top: 40%;
  }
}
cx-quote-list table tbody .quote-approved {
  color: var(--cx-color-primary);
}
cx-quote-list table tbody .quote-cancelled {
  color: var(--cx-color-warning);
}
cx-quote-list table tbody .quote-draft,
cx-quote-list table tbody .quote-created,
cx-quote-list table tbody .quote-expired {
  color: var(--cx-color-dark);
}
cx-quote-list table tbody .quote-ordered,
cx-quote-list table tbody .quote-offer,
cx-quote-list table tbody .quote-pending,
cx-quote-list table tbody .quote-request {
  color: var(--cx-color-text);
}
cx-quote-list table tbody .quote-rejected {
  color: var(--cx-color-danger);
}
cx-quote-list table tbody .quote-submitted {
  color: var(--cx-color-success);
}
cx-quote-list .cx-sort.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 0;
}
@media (min-width: 768px) {
  cx-quote-list .cx-sort.top cx-pagination {
    display: none;
  }
}
cx-quote-list .cx-sort.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-sort {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  cx-quote-list cx-pagination {
    margin: 0 auto;
  }
}
cx-quote-list .cx-form-group {
  width: 40%;
}
cx-quote-list .cx-form-group .sortBy-heading {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
}
cx-quote-list .cx-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 991.98px) {
  cx-quote-list .cx-form-group {
    padding-inline-start: 0;
    width: 100%;
  }
}
cx-quote-list .cx-empty {
  font-size: var(--cx-font-size, 1rem);
  font-weight: "normal";
}
cx-quote-list .cx-ghost-sort-top,
cx-quote-list .cx-ghost-sort-bottom {
  display: flex;
  padding: 1rem 0;
  margin: 0;
}
cx-quote-list .cx-ghost-select,
cx-quote-list .cx-ghost-pagination {
  width: 30%;
  height: 50px;
  background-color: var(--cx-color-ghost);
}
cx-quote-list .cx-ghost-sort-top {
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}
cx-quote-list .cx-ghost-sort-top .cx-ghost-title {
  width: 15%;
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-sort-top .cx-ghost-title {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-sort-top .cx-ghost-title {
    height: 25px;
  }
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-sort-top .cx-ghost-title {
    width: 45%;
  }
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-sort-top .cx-ghost-select {
    width: 100%;
  }
}
cx-quote-list .cx-ghost-sort-bottom {
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-sort-bottom {
    justify-content: center;
  }
}
cx-quote-list .cx-ghost-table {
  padding: 1rem 0;
}
cx-quote-list .cx-ghost-table .cx-ghost-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--cx-color-light);
  padding: 1rem;
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-name,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-id,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-status,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-updated-date,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-nav-caret {
    padding: 0.313rem 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-nav-caret {
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-nav-caret {
    display: none;
  }
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-updated-date {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-name {
    width: 20%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-id {
    width: 20%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-status {
    width: 15%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-updated-date {
    width: 30%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-cell-nav-caret {
    width: 15%;
  }
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-name,
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-id,
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-status,
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-updated-date,
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-nav-caret {
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-name,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-id,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-status,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-updated-date,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-nav-caret {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-name,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-id,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-status,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-updated-date,
  cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-nav-caret {
    height: 25px;
  }
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-name {
  width: 45%;
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-id {
  width: 35%;
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-status {
  width: 85%;
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-updated-date {
  width: 75%;
}
cx-quote-list .cx-ghost-table .cx-ghost-row .cx-ghost-nav-caret {
  width: 35%;
}
@media (min-width: 768px) {
  cx-quote-list .cx-ghost-table .cx-ghost-row:first-of-type .cx-ghost-name {
    width: 30%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row:first-of-type .cx-ghost-cell-updated-date {
    justify-content: flex-start;
    padding-inline-start: 3rem;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row:first-of-type .cx-ghost-cell-updated-date .cx-ghost-updated-date {
    width: 35%;
  }
  cx-quote-list .cx-ghost-table .cx-ghost-row:first-of-type .cx-ghost-cell-nav-caret {
    display: none;
  }
}

@media (max-width: 991.98px) {
  html[dir=rtl] cx-quote-list table tbody .cx-nav-caret {
    left: 5px;
  }
}

@media (max-width: 991.98px) {
  html[dir=ltr] cx-quote-list table tbody .cx-nav-caret {
    right: 5px;
  }
}

cx-quote-request-button {
  padding-block-end: 1rem;
  padding-inline-start: 3rem;
}
@media (max-width: 991.98px) {
  cx-quote-request-button {
    padding-inline-start: 0;
  }
}

@media (min-width: 992px) {
  cx-quote-summary:not(:empty) {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
  }
}
@media (min-width: 992px) {
  cx-quote-summary:not(:empty) .cx-container {
    padding: 20px;
    border: 1px solid var(--cx-color-medium);
    border-radius: var(--cx-border-radius, 1rem);
  }
}
cx-quote-summary:not(:empty) .cx-ghost-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
cx-quote-summary:not(:empty) .cx-ghost-actions .cx-ghost-button {
  width: 100%;
  height: 50px;
  background-color: var(--cx-color-ghost);
}
cx-quote-summary:not(:empty) .cx-ghost-title,
cx-quote-summary:not(:empty) .cx-ghost-summary-label,
cx-quote-summary:not(:empty) .cx-ghost-summary-amount {
  background-color: var(--cx-color-ghost);
}
@media (max-width: 767.98px) {
  cx-quote-summary:not(:empty) .cx-ghost-title,
  cx-quote-summary:not(:empty) .cx-ghost-summary-label,
  cx-quote-summary:not(:empty) .cx-ghost-summary-amount {
    height: 35px;
  }
}
@media (min-width: 768px) {
  cx-quote-summary:not(:empty) .cx-ghost-title,
  cx-quote-summary:not(:empty) .cx-ghost-summary-label,
  cx-quote-summary:not(:empty) .cx-ghost-summary-amount {
    height: 25px;
  }
}
cx-quote-summary:not(:empty) .cx-ghost-summary-heading,
cx-quote-summary:not(:empty) .cx-ghost-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-heading .cx-ghost-title {
  width: 100%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials {
  padding-block-end: 1rem;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row {
  justify-content: space-between;
  padding-block-start: 1rem;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(1) .cx-ghost-summary-label {
  width: 45%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(1) .cx-ghost-summary-amount {
  width: 35%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(2) .cx-ghost-summary-label {
  width: 55%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(2) .cx-ghost-summary-amount {
  width: 20%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(3) .cx-ghost-summary-label {
  width: 35%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(3) .cx-ghost-summary-amount {
  width: 35%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(4) .cx-ghost-summary-label {
  width: 55%;
}
cx-quote-summary:not(:empty) .cx-ghost-summary-partials .cx-ghost-row:nth-child(4) .cx-ghost-summary-amount {
  width: 20%;
}

cx-quote-summary-actions:not(:empty) section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  background-color: var(--cx-color-inverse);
}
@media (max-width: 767.98px) {
  cx-quote-summary-actions:not(:empty) section.fixed {
    width: 95%;
    padding-inline-end: 1.5rem;
    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
    position: fixed;
  }
  cx-quote-summary-actions:not(:empty) section.sticky {
    width: 100%;
    padding-inline-end: 0;
    padding-block-start: 1rem;
    padding-block-end: 0;
    position: sticky;
    position: -webkit-sticky;
  }
}
@media (min-width: 768px) {
  cx-quote-summary-actions:not(:empty) section {
    width: 100%;
    padding-block-start: 1rem;
    position: static;
  }
}
.cx-theme-high-contrast-dark cx-quote-summary-actions:not(:empty) section, .cx-theme-high-contrast-light cx-quote-summary-actions:not(:empty) section {
  background-color: var(--cx-color-background);
}

cx-quote-summary-prices:not(:empty) .cx-price-heading {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-block-end: 20px;
}
cx-quote-summary-prices:not(:empty) .cx-price-footer {
  font-style: italic;
  font-size: var(--cx-font-size, 0.85rem);
  font-weight: var(--cx-font-weight-normal);
}
cx-quote-summary-prices:not(:empty) .cx-price-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
cx-quote-summary-prices:not(:empty) .cx-price-savings {
  color: var(--cx-color-success);
}
cx-quote-summary-prices:not(:empty) .cx-price-total {
  font-weight: var(--cx-font-weight-bold);
}

cx-quote-summary-seller-edit .cx-seller-edit-body {
  padding: 20px;
  background: var(--cx-color-background);
  border: 1px solid var(--cx-color-medium);
  margin-block-start: 1rem;
}
cx-quote-summary-seller-edit .cx-seller-edit-body .cx-discount-input {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
cx-quote-summary-seller-edit .cx-seller-edit-body .cx-discount-input .btn-secondary {
  max-width: 6.5rem;
  height: fit-content;
}
cx-quote-summary-seller-edit .cx-seller-edit-body .cx-validation-msg {
  color: var(--cx-color-danger);
  font-size: 14px;
}
cx-quote-summary-seller-edit .cx-seller-edit-body .cx-validation-msg cx-icon {
  padding-inline-end: 5px;
}

cx-page-layout.QuoteDetailsPageTemplate {
  max-width: var(--cx-page-width-max);
  padding: 0 1.5rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 2rem;
}
cx-page-layout.QuoteDetailsPageTemplate .BodyContent {
  --cx-flex-basis: 70%;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
  cx-page-layout.QuoteDetailsPageTemplate .BodyContent {
    --cx-flex-basis: 100%;
  }
}
cx-page-layout.QuoteDetailsPageTemplate .CenterRightContent {
  --cx-flex-basis: 30%;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 992px) {
  cx-page-layout.QuoteDetailsPageTemplate .CenterRightContent {
    padding-inline-start: 1rem;
    padding-block-start: 9rem;
    padding-block-end: 1rem;
  }
}
@media (min-width: 992px) {
  cx-page-layout.QuoteDetailsPageTemplate .CenterRightContent > * {
    flex: none;
  }
}
@media (max-width: 991.98px) {
  cx-page-layout.QuoteDetailsPageTemplate .CenterRightContent {
    --cx-flex-basis: 50%;
    justify-content: flex-end;
    flex-direction: row;
    margin: 2rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.QuoteDetailsPageTemplate .CenterRightContent {
    --cx-flex-basis: 100%;
    flex-direction: unset;
  }
}